import React, { useState } from "react";


/// React router dom
import { Link } from "react-router-dom";
import logo1 from "../../../images/wigzolite icons/shiprocketLogo.png";
import logoDark from "../../../images/wigzolite icons/shiprocket-logo-dark.png";
import favIconDark from "../../../images/wigzolite icons/shiprocket-logo-favIcon-dark.png";

/// images


const NavHader = () => {
   const [toggle, setToggle] = useState(false);
   const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
   const authDetails = JSON.parse(localStorage.getItem("authDetails"));

   return (
      <div className="nav-header" style={{ 'width': 'auto' }}>
         <Link to="/" className={orgDetails.permissions !== 'granted' || authDetails.onboarding_status === false ? 'pointer-event-none brand-logo' : 'brand-logo'}>
            <img className="logo-abbr" src={favIconDark} alt="" />
            <img className="logo-compact" src={logoDark} alt="" />
            <img className="brand-title" src={logoDark} alt="" style={{ 'display': 'block' }} />
         </Link>

         {/* <div className="nav-control" onClick={() => {
            setToggle(!toggle);

            toggle == false ? document.querySelector(".overlay").style.display = "none" : document.querySelector(".overlay").style.display = "none";
            document.getElementById("org").style.display = "none";
            document.querySelector(".parentOrg").style.left = "13.1%";
            document.getElementById("org").style.visibility = "collapse";
            document.querySelector(".overlay").style.display = "none";
            document.getElementById('toggleArrow').style.transform = "rotate(0deg)";
         }
         }>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div> */}
      </div>
   );
};

export default NavHader;
