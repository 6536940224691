import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../../css/login.css";
import "../../css/landingPage.css";
import shopifyLogo from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/shopifylogo.svg";
import { Link } from "react-router-dom";

const ChannelList = () => {
    const state = useSelector((state) => state);
    var channellistData = JSON.parse(localStorage.getItem('userDetails'));
    return (
        <div className="row content-body">
            <div className="col-lg-12 col-md-12">
                <div className="channels-permission-list">
                    <div className="headings" >
                        <h2 className="heading" >Connect Your Channel</h2>
                        <h5 className="sub-heading" >These are the channels that are integrated in your account</h5>
                    </div>
                    <div className="table-content">

                        <div className="table-headings">
                            <span>Channel</span>
                            <span>Channel ID & Name</span>
                            <span>Status</span>
                            <span>Actions</span>
                        </div>
                        {(JSON.parse(channellistData.channel_list)).map((obj, i) => {
                            return (
                                <div className="table-values">
                                    <span className="col-values" ><img src={shopifyLogo}></img></span>
                                    <span className="col-values" >{obj?.active_store_details?.id || '-'} <br /> {obj?.active_store_details?.name || '-'}</span>
                                    <span className="col-values" style={{ color: '#0F9F09' }}>{obj?.status || '-'}</span>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <a className="grant-permission" href={'https://' + obj.redirectUrl} >
                                            Grant Permission
                                        </a>
                                        <div className="popover__wrapper">
                                            <a href="#">
                                                <h2 className="popover__title"><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.7968 17.7083H13.3593V11.4583H11.7968V17.7083ZM12.4999 9.53123C12.743 9.53123 12.947 9.45311 13.1119 9.29686C13.2768 9.14061 13.3593 8.94095 13.3593 8.6979C13.3593 8.45484 13.2768 8.24651 13.1119 8.0729C12.947 7.89929 12.743 7.81248 12.4999 7.81248C12.2569 7.81248 12.0529 7.89929 11.8879 8.0729C11.723 8.24651 11.6405 8.45484 11.6405 8.6979C11.6405 8.94095 11.723 9.14061 11.8879 9.29686C12.0529 9.45311 12.2569 9.53123 12.4999 9.53123ZM12.4999 22.9166C11.0763 22.9166 9.73082 22.6432 8.46346 22.0963C7.1961 21.5495 6.08933 20.8029 5.14315 19.8568C4.19697 18.9106 3.45044 17.8038 2.90356 16.5364C2.35669 15.2691 2.08325 13.9149 2.08325 12.4739C2.08325 11.0503 2.35669 9.70484 2.90356 8.43748C3.45044 7.17012 4.19697 6.06769 5.14315 5.13019C6.08933 4.19269 7.1961 3.4505 8.46346 2.90363C9.73082 2.35675 11.085 2.08331 12.526 2.08331C13.9496 2.08331 15.2951 2.35675 16.5624 2.90363C17.8298 3.4505 18.9322 4.19269 19.8697 5.13019C20.8072 6.06769 21.5494 7.17012 22.0963 8.43748C22.6431 9.70484 22.9166 11.059 22.9166 12.5C22.9166 13.9236 22.6431 15.2691 22.0963 16.5364C21.5494 17.8038 20.8072 18.9106 19.8697 19.8568C18.9322 20.8029 17.8298 21.5495 16.5624 22.0963C15.2951 22.6432 13.9409 22.9166 12.4999 22.9166ZM12.526 21.3541C14.9739 21.3541 17.0572 20.4904 18.776 18.763C20.4947 17.0356 21.3541 14.9392 21.3541 12.4739C21.3541 10.026 20.4947 7.94269 18.776 6.22394C17.0572 4.50519 14.9652 3.64581 12.4999 3.64581C10.052 3.64581 7.96433 4.50519 6.2369 6.22394C4.50947 7.94269 3.64575 10.0347 3.64575 12.5C3.64575 14.9479 4.50947 17.0356 6.2369 18.763C7.96433 20.4904 10.0607 21.3541 12.526 21.3541Z" fill="#61576E" />
                                                </svg>
                                                </h2>
                                            </a>
                                            <div className="popover__content">
                                                <p className="popover__message">Convert required some additional permission from shopify for this account</p>
                                            </div>
                                        </div>
                                    </span>

                                </div>
                            );
                        })}
                    </div>

                </div>
            </div>
        </div>
    );
};
export default ChannelList;