import { isBefore, subDays } from "date-fns";
import { useFormik } from "formik";
import { TextField } from "material-ui";
import { getMuiTheme, MuiThemeProvider } from "material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DatePicker } from "rsuite";

const ScheduleCampaignV2 = (props) => {
  const scheduleCampaignList = [
    { key: "Send immediately ", value: "DRAFT" },
    { key: "Custom Date ", value: "SCHEDULED" },
    { key: "Recurring ", value: "RECURRING" },
  ];
  let epochedDT;
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      status: "DRAFT",
      scheduleAt: epochedDT,
      repeatStopDateTime: '',
      repeatAfterDays: "1",
      predefinedSegmentData: {

      },
    },
    validationSchema: props.currentValidationSchema,
    onSubmit: (values) => {
      //_handleSubmit(values)
      props.handleSubmission(values);
    },
  });
  // const getPredefinedSegmentData = () => {
  //   return {
  //     "newCustomAudienceData": {
  //       "name": "",
  //       "subtype": "CUSTOM",
  //       "description": "",
  //       "customer_file_source": "USER_PROVIDED_ONLY"
  //     },
  //     "criteria": {
  //       "criteria": {
  //         "criteria_type": "intermediate",
  //         "criteria_operation": "and",
  //         "criteria": []
  //       },
  //       "fields": ["email"]
  //     },
  //     "type": "predefined",
  //     "segmentId": "",
  //     "dates": {
  //       "startDate": "2020-02-26",
  //       "endDate": "2020-03-04"
  //     }
  //   };
  // };
  let steps = props.steps;
  let activeStep = props.activeStep;
  let isLastStep = props.steps.length - 1;

  let nextStyle = props.nextStyle;
  let disabled = props.disabled;
  const setScheduleStatusFn = (e) => {
    // setScheduleStatus(e.target.value);
    // broadcastFormData.status = e.target.value;
  };
  let convertToEpoch = (dt) => {
    return Math.trunc(dt / 1000);
  };
  const changeScheduleAt = (e) => {
    epochedDT = convertToEpoch(e);
    formik.setFieldValue("scheduleAt", epochedDT);
  };
  const changeRepeatStopDateTime = (e) => {
    formik.setFieldValue("repeatStopDateTime", e);
    // broadcastFormData.repeatStopDateTime = JSON.parse(JSON.stringify(e));
    // dispatch(broadcastFormDataAction(state));
  };
  const handleRepeatAfterDays = (e) => {
    formik.setFieldValue("repeatAfterDays", e.target.value);
  };
  const shouldDisableDates = (date) => {
    let minusOneDay = subDays(new Date(), 1);
    return isBefore(date, minusOneDay);
  };
  let predefinedSegmentData = {
    newCustomAudienceData: {
      name: "",
      subtype: "CUSTOM",
      description: "",
      customer_file_source: "USER_PROVIDED_ONLY",
    },
    criteria: {
      criteria: {
        criteria_type: "intermediate",
        criteria_operation: "and",
        criteria: [],
      },
      fields: ["email"],
    },
    type: "predefined",
    segmentId: props.formData.targetedAudience.id,
    dates: {
      startDate: moment(subDays(new Date(), 30)).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    },
  };
  const [isValid, setIsValid] = useState(false);
  const setScheduleTypeFn = (e) => {
    let status = e.target.value;
    formik.setFieldValue("status", status);
    if (props.formData.targetedAudience.segmentType === "predefined") {
      formik.setFieldValue("predefinedSegmentData", predefinedSegmentData);
      formik.values.mappedUserPhones = [];
      formik.values.mappedUserEmails = [];
      // formik.values.predefinedSegmentData.segmentId =
      //   props.formData.targetedAudience.id;
    }
    setIsValid(!!status);
  };
  useEffect(() => {
    if (!!formik.values.status) {
      if (props.formData.targetedAudience.segmentType === "predefined") {
        formik.setFieldValue("predefinedSegmentData", predefinedSegmentData);
        formik.values.mappedUserPhones = [];
        formik.values.mappedUserEmails = [];
        // formik.values.predefinedSegmentData.segmentId =
        //   props.formData.targetedAudience.id;
      }
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formik.isValid, formik.dirty]);
  return (
    <>
      <section>
        <div
          className="broadcast-channels-container"
        >
          <button
            disabled={!isValid}
            style={isValid ? nextStyle : disabled}
            form={steps[activeStep].label.split(" ").join("")}
          >
            {" "}
            {activeStep === isLastStep ? "Send" : "Next"}
          </button>

          <input type="hidden" name="predefinedSegmentData" value={JSON.stringify(predefinedSegmentData)} />
          <div className="d-flex">
            <form
              id="ScheduleCampaign"
              onSubmit={formik.handleSubmit}
              className="audience-left-container"
            >
              <div className="custom-card-container">
                <h3 className="custom-card-heading">Schedule campaign</h3>
                <div className="d-flex custom-card-body" style={{ marginTop: '20px' }}></div>
                <div style={{ padding: 0 }}>
                  <input
                    type="hidden"
                    name="scheduleAt"
                    value={formik.values.scheduleAt}
                  />
                  {!!scheduleCampaignList &&
                    scheduleCampaignList.map((targetobj, i) => {
                      let keyData = (
                        <div
                          className="col-md-10 audience-radio-container"
                          key={i}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            id={targetobj.key + i}
                            value={targetobj.value}
                            name="status"
                            checked={formik.values.status == targetobj.value}
                            onChange={setScheduleTypeFn}
                            required
                          />
                          <label
                            htmlFor={targetobj.key + i}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {targetobj.key}
                          </label>
                        </div>
                      );
                      return keyData;
                    })}
                </div>
              </div>
            </form>
            <div
              className="col-md-6 audience-right-container"
              style={{ padding: 0, display: 'flex', justifyContent: 'center' }}
            >
              <div
                style={{
                  display: formik.values.status === "RECURRING" || formik.values.status === "SCHEDULED" ? "flex" : "none",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label htmlFor="scheduleAt">Schedule At</label>
                <DatePicker
                  // value={}
                  format="dd-MM-yyyy HH:mm:ss"
                  id="scheduleAt"
                  cleanable={false}
                  placement={"bottomEnd"}
                  preventOverflow={true}
                  editable={false}
                  shouldDisableDate={shouldDisableDates}
                  // disabledHours={disabledHours}
                  // disabledMinutes={disabledMinutes}
                  onChange={changeScheduleAt}
                  className={`${formik.touched.scheduleAt && Boolean(formik.errors.scheduleAt) && 'error'}`}
                ></DatePicker>

                <div
                  style={{
                    display:
                      formik.values.status === "RECURRING" ? "flex" : "none",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MuiThemeProvider muiTheme={getMuiTheme()}>
                    <label htmlFor="repeatAfterDays">Repeat Every (days)</label>
                    <TextField
                      type="number"
                      name="repeatAfterDays"
                      autoComplete="off"
                      className="input-field-redux"
                      label="Repeat Every (days)"
                      id="repeatAfterDays"
                      min={1}
                      defaultValue={formik.values.repeatAfterDays}
                      onChange={handleRepeatAfterDays}
                      error={formik.touched.repeatAfterDays && Boolean(formik.errors.repeatAfterDays)}
                    />
                  </MuiThemeProvider>
                  <label htmlFor="repeatStopDateTime">Stop At</label>
                  <DatePicker
                    // value={formik.values.repeatStopDateTime}
                    format="dd-MM-yyyy HH:mm:ss"
                    id="repeatStopDateTime"
                    cleanable={false}
                    placement={"bottomEnd"}
                    preventOverflow={true}
                    editable={false}
                    shouldDisableDate={shouldDisableDates}
                    // disabledHours={disabledHours}
                    // disabledMinutes={disabledMinutes}
                    onChange={changeRepeatStopDateTime}
                    className={`${formik.touched.repeatStopDateTime && Boolean(formik.errors.repeatStopDateTime) && 'error'}`}
                  ></DatePicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ScheduleCampaignV2;
