import React from 'react';

export default function PhonePreviewWhatsapp(props) {
  return (

    <div className="whatsapp-preview-container">
      <div className="marvel-device note8">
        <div className="inner"></div>
        <div className="overflow">
          <div className="note8-shadow"></div>
        </div>
        <div className="speaker"></div>
        <div className="sleep"></div>
        <div className="volume"></div>
        <div className="camera"></div>
        <div className="screen" style={{}}>
          <div className="wp-top-bar" style={{}}>
            <div>
              <svg
                width="9"
                height="8"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 1.23345C1.02687 0.459103 2.30483 0 3.69002 0C5.07518 0 6.35313 0.459089 7.37999 1.23341L3.68998 5.72559L0
                   1.23345Z"
                  fill="#fff"
                ></path>
              </svg>
              <svg
                width="7"
                height="7"
                viewBox="0 0 6 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 6H6V0L0 6Z"
                  fill="#fff"
                ></path>
              </svg>
              <svg
                width="5"
                height="8"
                viewBox="0 0 4 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.66667 0.375V0L1.33333 0V0.375H0V6H4V0.375H2.66667Z"
                  fill="#fff"
                ></path>
              </svg>
              <span className="mobile-time">12:30</span>
            </div>
            <div>
              <div>
                <svg
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 3.9375H2.1375L5.2875 0.7875L4.5 0L0 4.5L4.5 9L5.2875 8.2125L2.1375 5.0625H9V3.9375Z"
                    fill="#fff"
                  ></path>
                </svg>
                <span></span>
                <div>
                  <span className="customer-bar">
                    {props.org_name}
                  </span>
                  <span></span>
                </div>
              </div>
              <div>
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.21222 5.2379C3.09222 6.81013 4.51 8.09346 6.23944 8.89901L7.58389 7.67679C7.74889 7.52679 7.99333 7.47679 8.20722                                       7.54346C8.89167 7.74901 9.63111 7.86013 10.3889 7.86013C10.725 7.86013 11 8.11013 11 8.41568V10.3546C11 10.6601 10.725 10.9101 10.3889 10.9101C4.65056 10.9101 0                                            6.68235 0 1.46568C0 1.16013 0.275 0.910126 0.611111 0.910126H2.75C3.08611 0.910126 3.36111 1.16013 3.36111 1.46568C3.36111 2.16013 3.48333 2.82679 3.70944                                              3.44901C3.77667 3.64346 3.72778 3.86013 3.55667 4.01568L2.21222 5.2379Z"
                    fill="#fff"
                  ></path>
                </svg>
                <svg
                  width="3"
                  height="10"
                  viewBox="0 0 3 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.87927 2.43158C2.49554 2.43158 2.99975 1.92736 2.99975 1.31109C2.99975 0.69483 2.49554 0.190613 1.87927 0.190613C1.26301 0.190613 0.758789 0.69483 0.758789 1.31109C0.758789 1.92736 1.26301 2.43158 1.87927 2.43158ZM1.87927 3.55206C1.26301 3.55206 0.758789 4.05628 0.758789 4.67254C0.758789 5.28881 1.26301 5.79302 1.87927 5.79302C2.49554 5.79302 2.99975 5.28881 2.99975 4.67254C2.99975 4.05628 2.49554 3.55206 1.87927 3.55206ZM1.87927 6.9135C1.26301 6.9135 0.758789 7.41772 0.758789 8.03399C0.758789 8.65025 1.26301 9.15447 1.87927 9.15447C2.49554 9.15447 2.99975 8.65025 2.99975 8.03399C2.99975 7.41772 2.49554 6.9135 1.87927 6.9135Z"
                    fill="#fff"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div className="msg">
            <div
              className="bubble"
              style={{
                display: props.message ? "block" : "none", width: !!props.replacedButtonUrl && "100%"
              }}
            >
              <div className="txt">
                <div className="img-div" style={{ display: !!props.attachment ? 'flex' : 'none', width: ' 100%', justifyContent: ' center' }}>
                  <img src={props.attachment} alt="attachments"
                    style={{ width: '100%', height: 'auto', borderRadius: '5px', wordWrap: 'break-word' }} />
                </div>
                <span className="message" id="preview-text">
                  {" "}
                  {props.message}
                  <p className="preview-footer">{props.footer}</p>
                </span>
              </div>
              <div className="bubble-arrow"></div>
            </div>
            {!!(props.replacedButtonUrl) && (props.replacedButtonUrl.length > 0) && JSON.parse(props.replacedButtonUrl).map((value, i) => {
              return (
                <div
                  className="bubble"
                  style={{ width: '100%', display: 'flex' }}
                  key={i}
                >
                  <div className="txt" style={{ padding: '3px' }}>
                    <a className="message" style={{ cursor: "default", color: '#338FF5' }}>{value.button_name || value.phoneButtonName}</a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>

  );
}
