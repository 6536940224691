import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { broadcastFormDataAction } from "../../../../../store/actions/PostActions";
import { useFormik } from "formik";
import "../../../css/journey-css.css";
import "./broadcast.css";
import broadcastFormData from "./FormData";
import whatsapp_filled_icon from "../../../images/wigzolite icons/wigzolite-icons/broadcast/whatsapp_filled_icon.svg";
import email_filled_icon from "../../../images/wigzolite icons/wigzolite-icons/broadcast/email_filled_icon.svg";
import sms_filled_icon from "../../../images/wigzolite icons/wigzolite-icons/broadcast/sms_filled_icon.svg";
import push_filled_icon from "../../../images/wigzolite icons/wigzolite-icons/broadcast/push_filled_icon.svg";
import onsite_filled_icon from "../../../images/wigzolite icons/wigzolite-icons/broadcast/onsite_filled_icon.svg";

// import "../../../../../css/journey-css.css";
// import "../../../../../css/broadcast.css";
const ChannelV2 = (props) => {
  // const Formik = useFormik();
  // const state = useSelector((state) => state);
  // const dispatch = useDispatch();
  //const { selectedChannel } = props;
  //const formikContext = props.formikContext;
  //console.log('formikConrect',formikContext)
  let history = useHistory();
  const initialValue = {
    selectedChannel: props.isComleted
      ? props.formData.selectedChannel
      : broadcastFormData.selectedChannel,
  };
  const formik = useFormik({
    initialValues: {
      selectedChannel: "whatsapp",
    },
    validationSchema: props.currentValidationSchema,
    onSubmit: (values) => {
      //_handleSubmit(values)
      props.handleSubmission(values);
    },
  });
  let steps = props.steps;
  let activeStep = props.activeStep;
  let isLastStep = props.steps.length - 1;

  let nextStyle = props.nextStyle;
  let disabled = props.disabled;
  const channelList = [
    {
      key: "WhatsApp",
      value: "whatsapp",
      disabledImgUrl: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7983 11.4924C14.7593 11.4737 13.3013 10.7557 13.0422 10.6624C12.9364 10.6244 12.8231 10.5874 12.7026 10.5874C12.5057 10.5874 12.3403 10.6855 12.2115 10.8782C12.0658 11.0947 11.6249 11.6101 11.4887 11.7641C11.4709 11.7844 11.4466 11.8087 11.4321 11.8087C11.419 11.8087 11.1934 11.7157 11.1251 11.6861C9.56105 11.0066 8.37385 9.3728 8.21105 9.0973C8.18775 9.0577 8.18675 9.0397 8.18665 9.0397C8.19235 9.0187 8.24495 8.966 8.27205 8.9388C8.35145 8.8602 8.43755 8.7566 8.52075 8.6565C8.56015 8.609 8.59965 8.5615 8.63845 8.5166C8.75915 8.3761 8.81295 8.267 8.87535 8.1407L8.90795 8.075C9.06015 7.7726 8.93015 7.5174 8.88815 7.435C8.85365 7.3661 8.23805 5.8802 8.17255 5.7241C8.01515 5.3474 7.80715 5.172 7.51815 5.172C7.49135 5.172 7.51815 5.172 7.40565 5.1768C7.26875 5.1826 6.52295 5.2807 6.19325 5.4886C5.84355 5.7091 5.25195 6.4118 5.25195 7.6476C5.25195 8.7599 5.95785 9.8101 6.26085 10.2095C6.26845 10.2196 6.28225 10.24 6.30235 10.2694C7.46285 11.9643 8.90975 13.2204 10.3764 13.8063C11.7884 14.3703 12.457 14.4355 12.8371 14.4355C12.8372 14.4355 12.8372 14.4355 12.8372 14.4355C12.9969 14.4355 13.1248 14.423 13.2376 14.4119L13.3091 14.4051C13.7969 14.3618 14.8689 13.8064 15.1127 13.1288C15.3048 12.5951 15.3554 12.012 15.2277 11.8004C15.1401 11.6565 14.9893 11.5841 14.7983 11.4924Z"
            fill="#808080"
          />
          <path
            d="M10.1775 0C4.76092 0 0.354128 4.3736 0.354128 9.7495C0.354128 11.4883 0.819527 13.1903 1.70093 14.6799L0.0137288 19.6568C-0.0176712 19.7496 0.00572814 19.8522 0.074328 19.9221C0.123928 19.9727 0.191128 20 0.259728 20C0.286028 20 0.312528 19.996 0.338328 19.9878L5.52802 18.3387C6.94812 19.0974 8.55322 19.498 10.1776 19.498C15.5937 19.498 20 15.1248 20 9.7495C20 4.3736 15.5937 0 10.1775 0ZM10.1775 17.4671C8.64902 17.4671 7.16852 17.0257 5.89592 16.1907C5.85322 16.1626 5.80352 16.1481 5.75352 16.1481C5.72712 16.1481 5.70062 16.1522 5.67482 16.1604L3.07512 16.9867L3.91432 14.5108C3.94152 14.4307 3.92792 14.3423 3.87792 14.274C2.90882 12.9498 2.39652 11.3854 2.39652 9.7495C2.39652 5.4935 5.88712 2.0309 10.1774 2.0309C14.4673 2.0309 17.9574 5.4935 17.9574 9.7495C17.9575 14.005 14.4674 17.4671 10.1775 17.4671Z"
            fill="#808080"
          />
        </svg>
      ),
      imgUrl: whatsapp_filled_icon,
      descriptionText: "Send whatsapp broadcast message to your users.",
      disableCategory: false,
      checked:
        props.formData.selectedChannel == "whatsapp" ||
          formik.values.selectedChannel == "whatsapp"
          ? true
          : false,
    },
    {
      key: "Onsite",
      value: "onsite",
      disabledImgUrl: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.557 3.21094H2.44296C1.09597 3.21094 0 4.30691 0 5.65389V14.3457C0 15.6927 1.09597 16.7886 2.44296 16.7886H17.557C18.904 16.7886 20 15.6927 20 14.3457V5.65416C20.0003 4.30691 18.9043 3.21094 17.557 3.21094ZM16.3633 4.80469L10 8.90435L3.63668 4.80469H16.3633ZM17.557 15.1952H2.44296C1.97466 15.1952 1.59375 14.814 1.59375 14.346V5.69932L9.50833 10.7887C9.51895 10.7953 9.53037 10.7999 9.54126 10.806C9.55269 10.8123 9.56437 10.8184 9.57606 10.8243C9.63742 10.8559 9.70064 10.8814 9.76519 10.8981C9.77183 10.9 9.77847 10.9008 9.78511 10.9024C9.85603 10.9191 9.92775 10.9295 9.99947 10.9295C9.99973 10.9295 10 10.9295 10 10.9295C10.0005 10.9295 10.0008 10.9295 10.0011 10.9295C10.0728 10.9295 10.1445 10.9194 10.2154 10.9024C10.2221 10.9008 10.2287 10.9 10.2353 10.8981C10.2999 10.8814 10.3628 10.8559 10.4245 10.8243C10.4362 10.8184 10.4478 10.8123 10.4593 10.806C10.4702 10.7999 10.4816 10.7953 10.4922 10.7887L18.4068 5.69932V14.3457C18.4065 14.814 18.0253 15.1952 17.557 15.1952Z"
            fill="#808080"
          />
        </svg>
      ),
      imgUrl: onsite_filled_icon,
      descriptionText: "Send Email broadcast message to your users.",
      disableCategory: true,
      checked: props.formData.selectedChannel == "email" ||
        formik.values.selectedChannel == "email"
        ? true
        : false

    },
    {
      key: "Email",
      value: "email",
      disabledImgUrl: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.557 3.21094H2.44296C1.09597 3.21094 0 4.30691 0 5.65389V14.3457C0 15.6927 1.09597 16.7886 2.44296 16.7886H17.557C18.904 16.7886 20 15.6927 20 14.3457V5.65416C20.0003 4.30691 18.9043 3.21094 17.557 3.21094ZM16.3633 4.80469L10 8.90435L3.63668 4.80469H16.3633ZM17.557 15.1952H2.44296C1.97466 15.1952 1.59375 14.814 1.59375 14.346V5.69932L9.50833 10.7887C9.51895 10.7953 9.53037 10.7999 9.54126 10.806C9.55269 10.8123 9.56437 10.8184 9.57606 10.8243C9.63742 10.8559 9.70064 10.8814 9.76519 10.8981C9.77183 10.9 9.77847 10.9008 9.78511 10.9024C9.85603 10.9191 9.92775 10.9295 9.99947 10.9295C9.99973 10.9295 10 10.9295 10 10.9295C10.0005 10.9295 10.0008 10.9295 10.0011 10.9295C10.0728 10.9295 10.1445 10.9194 10.2154 10.9024C10.2221 10.9008 10.2287 10.9 10.2353 10.8981C10.2999 10.8814 10.3628 10.8559 10.4245 10.8243C10.4362 10.8184 10.4478 10.8123 10.4593 10.806C10.4702 10.7999 10.4816 10.7953 10.4922 10.7887L18.4068 5.69932V14.3457C18.4065 14.814 18.0253 15.1952 17.557 15.1952Z"
            fill="#808080"
          />
        </svg>
      ),
      imgUrl: email_filled_icon,
      descriptionText: "Send Email broadcast message to your users.",
      disableCategory: true,
      checked: props.formData.selectedChannel == "email" ||
        formik.values.selectedChannel == "email"
        ? true
        : false

    },
    {
      key: "Web Push",
      disabledImgUrl: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_12_1121)">
            <path
              d="M9.99997 0C11.0622 0 11.9366 0.804481 12.047 1.83742C12.7946 1.0667 13.8414 0.588235 15 0.588235C17.2741 0.588235 19.1176 2.43177 19.1176 4.70588C19.1176 6.57096 17.8776 8.14643 16.1771 8.65283L16.1764 13.5294H16.4706C17.6076 13.5294 18.5294 14.4512 18.5294 15.5882C18.5294 16.7253 17.6076 17.6471 16.4706 17.6471L13.0343 17.6475C12.5497 19.0181 11.2424 20 9.70585 20C8.16928 20 6.86204 19.0181 6.37742 17.6475L2.94115 17.6471C1.80409 17.6471 0.882324 16.7253 0.882324 15.5882C0.882324 14.4512 1.80409 13.5294 2.94115 13.5294H3.23527V8.23529C3.23527 5.49178 4.94271 3.14705 7.35294 2.2058L7.35291 2.05882C7.35291 0.959669 8.21425 0.0616869 9.29877 0.00304278L9.41174 0H9.99997ZM11.7436 17.6477H7.66807C8.075 18.3507 8.8352 18.8235 9.70585 18.8235C10.5765 18.8235 11.3367 18.3507 11.7436 17.6477ZM16.4706 14.7059H2.94115C2.45384 14.7059 2.05879 15.1009 2.05879 15.5882C2.05879 16.0755 2.45384 16.4706 2.94115 16.4706H16.4706C16.9579 16.4706 17.3529 16.0755 17.3529 15.5882C17.3529 15.1009 16.9579 14.7059 16.4706 14.7059ZM9.70585 2.94118C6.83421 2.94118 4.49657 5.22754 4.41399 8.0792L4.41174 8.23529V13.5294H15V8.82353C12.7259 8.82353 10.8823 6.98 10.8823 4.70588C10.8823 4.15599 10.9901 3.63128 11.1857 3.15171C10.7644 3.02829 10.3205 2.95671 9.86195 2.94343L9.70585 2.94118ZM15 1.76471C13.3756 1.76471 12.0588 3.08152 12.0588 4.70588C12.0588 6.33025 13.3756 7.64706 15 7.64706C16.6243 7.64706 17.9411 6.33025 17.9411 4.70588C17.9411 3.08152 16.6243 1.76471 15 1.76471ZM9.99997 1.17647H9.41174C8.98998 1.17647 8.63733 1.47238 8.55009 1.86792C8.92571 1.79996 9.31166 1.76471 9.70585 1.76471C10.1 1.76471 10.486 1.79995 10.8607 1.86747C10.7744 1.47238 10.4217 1.17647 9.99997 1.17647Z"
              fill="#808080 "
            />
          </g>
          <defs>
            <clipPath id="clip0_12_1121">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      value: "push",
      imgUrl: push_filled_icon,
      descriptionText: "Send push broadcast message to your users.",
      disableCategory: true,
      // checked:state.broadcastFormData.selectedChannel=='push'?true:false
    },
    {
      key: "SMS",
      value: "sms",
      imgUrl: sms_filled_icon,
      descriptionText: "Send SMS broadcast message to your users.",
      disabledImgUrl: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 16.9079V4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H15.8333C16.7538 2.5 17.5 3.24619 17.5 4.16667V12.5C17.5 13.4205 16.7538 14.1667 15.8333 14.1667H6.63437C6.12807 14.1667 5.64922 14.3968 5.33292 14.7922L3.39043 17.2202C3.09518 17.5893 2.5 17.3806 2.5 16.9079Z"
            stroke="#808080"
            strokeWidth="1.5"
          />
        </svg>
      ),
      disableCategory: true,
      checked:
        props.formData.selectedChannel == "sms" ||
          formik.values.selectedChannel == "sms"
          ? true
          : false

    },
  ];
  //const {setFieldValue} = useFormikContext();
  const setChannelNameFn = (e) => {
    // formik.values.selectedChannel = e.target.value
    //  setFieldValue("selectedChannel", e.target.value)
  };
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (formik.values.selectedChannel || props.formData.selectedChannel) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    // props.handleButtonState(formik.isValid && formik.dirty)
  }, [formik.isValid, formik.dirty]);

  return (
    <>
      <section>
        {/* <button
          disabled={!isValid}
          onSubmit={props.handleSubmission}
          style={isValid ? nextStyle : disabled}
          form={steps[activeStep].label.split(" ").join("")}
        >
          {" "}
          {activeStep === isLastStep ? "Send" : "Next"}
        </button>
        <button type="button"
          onClick={() => history.push('/broadcast/broadcast-overview')}
          style={{ position: 'absolute', right: '8.5rem', top: '7rem', background: 'rgb(255, 255, 255)', border: '1px solid rgb(76, 2, 255)', color: 'rgb(76, 2, 255)', borderRadius: '5px', fontSize: '0.875rem', fontWeight: '600', padding: '0.625rem 1rem', float: 'right', marginRight: '1rem' }}>Back</button> */}
        <form onSubmit={formik.handleSubmit} id="Channel">

          <div
            style={{ paddingTop: "0px", }}
            className="broadcast-channels-container"
          >

            <div className="custom-card-container">
              <h3 className="custom-card-heading">Select Channel</h3>

              <div className="d-flex custom-card-body">
                {
                  channelList.map((channels, i) => {
                    let keyData = (
                      <div key={i}
                        className={`${channels.disableCategory ? 'disabled-custom-card' : ''} custom-card`}
                        style={channels.checked ? { border: '1px solid #9269F6', boxShadow: '-2px 2px 4px #D7D7D9' } : {}}>
                        <img src={channels.imgUrl} />
                        <h4>{channels.key}</h4>
                        {/* <span>{channels.subheading}</span> */}
                        {channels.disableCategory
                          ? 'Coming Soon'
                          :
                          <button
                            className="btn btn-outline-primary btn-xs"
                            disabled={channels.disable}
                          >
                            {"Select"}
                          </button>
                        }
                      </div>
                    );
                    return keyData;
                  })
                }
              </div>
            </div>

            {/* 
            <h4 style={{ marginBottom: '0.5rem', marginLeft: '5px' }}>Select channel for this campaign </h4>
            <form onSubmit={formik.handleSubmit} id="Channel">
              <div className="row">
                {channelList.map((channels, i) => {
                  let keyData = (
                    <>
                      <div
                        tabIndex={channels.disableCategory ? 1 : ""}
                        className={
                          channels.checked
                            ? "recipe-cards-active col-md-2-4 col-xl-3 recipe-cards"
                            : "col-md-2-4 col-xl-3 recipe-cards"
                        }
                        style={{
                          height: "300px",
                          background: channels.disableCategory
                            ? "#fff"
                            : "#cbcbcb",
                        }}
                      >
                        {!channels.disableCategory ? (
                          <div className="disabledComingSoon">Coming Soon...</div>
                        ) : (
                          <div></div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                          }}
                        >
                          <h4 style={{ marginLeft: "38px" }}>{channels.key}</h4>
                          {channels.disableCategory
                            ? channels.imgUrl
                            : channels.disabledImgUrl}
                        </div>

                        <p>{channels.descriptionText}</p>
                        <div className="recipe-radio-container">
                          <input
                            checked={
                              formik.values.selectedChannel === channels.value
                            }
                            name="selectedChannel"
                            type="radio"
                            id={channels.key + i}
                            value={channels.value}
                            onChange={formik.handleChange}
                            required
                          />
                          <label
                            htmlFor={channels.key + i}
                            name={"selectedChannel"}
                            id={i}
                            value={channels.value}
                            className="recipe-radio-label"
                            style={{
                              border:
                                !channels.disableCategory && "1px solid grey",
                              color: !channels.disableCategory && "grey",
                              background:
                                !channels.disableCategory && "transparent",
                              cursor: !channels.disableCategory && "not-allowed",
                            }}
                          >
                            {channels.checked ? "Selected" : "Select"}
                          </label>
                        </div>
                      </div>
                    </>
                  );
                  return keyData;
                })}
              </div>
            </form> */}
          </div>
        </form>
      </section>
    </>
  );
};

export default ChannelV2;
