import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import JourneyOverview from "../Journey/JourneyOverview";
import { lazy } from "react";
const JourneyMarkup = (propsData) => {
  document.title = "SR Convert Journeys";
  const JourneyCreate = lazy(() => import("../Journey/JourneyCreate"));

  let { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          path={`${path}/journeys-overview`}
          exact
          component={JourneyOverview}
        ></Route>
        <Route
          path={`${path}/create-journey`}
          exact
          component={JourneyCreate}
        ></Route>
        <Redirect from={`${path}`} to={`${path}/journeys-overview`}></Redirect>
      </Switch>
    </>
  );
};

export default JourneyMarkup;
