export const isAuthenticated = (state) => {
    if (state.auth.auth.api_token) return true;
    else
        return false;
};
export const isAuthorized = (state) => {
    if (state.auth.org.org_token) return true;
    else
        return false;
};
