let broadcastFormData = {
  selectedChannel: "",
  campaignName: "",
  selectedTemp: "",
  selectedTempName: "",
  status: "",
  repeatAfterDays: 1,
  message: "",
  variables: [],
  dropdownVars: {},
  replacedVariables: {},
  isUnicode: false,
  crmName: "",
  coupon: "",
  mediaUrl: "",
  targetedAudience: {
    segmentType: "sendToAll",
    selectedSegment: "sendToAll",
    selectedSegmentName: "sendToAll",
    estimateReach: 0
  },
  removeDuplicates: true,
  mapped_headers: [],
  csvDetails: {},
  isArabianText: false,
  addToContactList: false,
  isScheduled: false,
  scheduleAt: Math.trunc(new Date(new Date()).getTime() / 1000),
  repeatStopDateTime: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000),
  expireAfter: Math.trunc((new Date().getTime() + (30 * 60 * 1000)) / 1000),
  replacedButtonUrl: [],
  replacedAttachment: "",
  broadcastTemplateUuid: "",
  broadcastType: "BROADCAST",
  predefinedSegmentData: {},
  testSizeRatio: 50,
};
export default broadcastFormData;