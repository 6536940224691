const initialState = {};
// const revenueInitialState = {
//   revenue: {
//     graphData: {
//       ySeries: [],
//     },
//   },
//   orders: [],
// };
const broadcastFormInitState = {
  selectedChannel: "",
  campaignName: "",
  selectedTemp: "",
  selectedTempName: "",
  status: "DRAFT",
  repeatAfterDays: 1,
  message: "",
  variables: [],
  isUnicode: false,
  crmName: "",
  coupon: "",
  //smsImg: '',
  mediaUrl: "",
  // selectedSmtpType: $scope.smtpTypes[1].key,
  targetedAudience: {
    segmentType: "sendToAll",
    selectedSegment: null,
  },
  removeDuplicates: true,
  mapped_headers: [],
  csvDetails: {},
  isArabianText: false,
  addToContactList: false,
  isScheduled: false,
  scheduleAt: '', //ignore if campaign is not scheduled
  repeatStopDateTime: '', // 10 days in future
  /*repeatStopDateTime: ((date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.format("yyyy-mm-dd HH:MM:ss");
  })(new Date(), 10),*/
  expireAfter: '',
  broadcastTemplateUuid: "", // TODO THIS SHOULD BE REMOVED WHEN UI MADE
  broadcastType: "BROADCAST", // TODO THIS SHOULD BE DEFAULT
  predefinedSegmentData: {},
  testSizeRatio: 50,
  replacedButtonUrl:[]
};
export function JourneyReducer(state = initialState, actions) {
  return {
    ...state,
  };
}
export function BroadcastReducer(state = broadcastFormInitState, action) {
  if (action.type === "BROADCAST_FORM_DATA") {
    return {
      ...state,
    };
  }
  return state;
}

export function RevenueReducer(state = initialState, actions) {
  return {
    ...state,
  };
}
