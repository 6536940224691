import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Drop from "react-dropzone-uploader";
import { Row, Card, Col, Button, Modal } from "react-bootstrap";
import "../../css/galleryModal.css";
import { store } from "../../store/store";
import { deleteGalleryImage, getImagesList, uploadGalleryImages } from "../../services/GetService";
import Pagination from "../components/Dashboard/Home/Paignation";
import moment from "moment";
import format from "date-fns/format";
import deleteIcon from "../../images/wigzolite icons/wigzolite-icons/common/delete_icon.svg";
import swal from "sweetalert";
import cloudUploadIcon from "../../images/wigzolite icons/wigzolite-icons/common/cloud--upload.svg";
import galleryIcon from "../../images/wigzolite icons/wigzolite-icons/common/gallery.svg";

const GalleryModal = (props) => {
    let galleryNav = {
        IMAGE_LIBRARY: "image_library",
        UPLOAD: "upload",
        PRODUCT_CATALOG: "product_catalog"
    };
    const state = store.getState();
    const [loadingImages, setLoadingImages] = useState(true);
    const [selectedTab, setSelectedTab] = useState(galleryNav.IMAGE_LIBRARY);
    const [openModal, setOpenModal] = useState(false);
    const [imagesList, setImagesList] = useState({});
    const [imagesPaginatedData, setImagesPaginatedData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [showInvalidImageFormatError, setShowInvalidImageFormatError] = useState(false);
    let perPage = 20;
    let galleryNavData = [{
        label: 'Image Library', value: galleryNav.IMAGE_LIBRARY
    }, {
        label: 'Upload', value: galleryNav.UPLOAD
    },
        //  {
        //     label: 'Product Catalog', value: galleryNav.PRODUCT_CATALOG, iconUrl: ''
        // }
    ];
    function getImagesListFn() {
        setSelectedTab('image_library');
        setLoadingImages(true);
        getImagesList(state.auth.org.org_token).then((res) => {
            setLoadingImages(false);
            setImagesList(res.data.images);
            setTotalItems(res.data.count);
            pageChange(1, res.data.images);
        }).catch((error) => {
            console.error(error);
        });
    }
    const getImageFormat = (imageName) => {
        if (!!imageName) {
            return imageName.split(".").pop();
        }
    };
    const switchTab = (value) => {
        setSelectedTab(value);
    };
    const handleChangeStatus = ({ meta, file }, status, allFiles) => {
        console.log(status, meta, file);
        status === 'rejected_file_type' && setShowInvalidImageFormatError(true);
        if (status === 'done') {
            uploadGalleryImages(state.auth.org.org_token, file).then((res) => {
                console.log(res);
                allFiles.forEach(f => f.remove());
                getImagesListFn();
            }).catch((error) => {
                console.error(error);
            });
        }
    };
    const selectAsset = (mediaFileUrl, type, fileSize, fileName) => {
        props.handleSelection({ mediaFileUrl, type, fileSize, fileName });
        setOpenModal(false);
    };
    const deleteImage = (mediaFileId) => {
        let imageMediaFileIds = `imageMediaFileIds%5B%5D=${mediaFileId}`;

        swal({
            title: "Are you sure?",
            text: 'Do you want to delete?',
            icon: "warning",
            closeOnClickOutside: true,
            buttons: {
                cancel: {
                    text: "Cancel",
                    visible: true,
                    className: "btn btn-light btn-sm"
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    closeModal: false,
                    className: "btn btn-primary btn-sm swal-primary-btn"
                },
            },
        }).then((isConfirm) => {
            if (!!isConfirm) {
                deleteGalleryImage(state.auth.org.org_token, imageMediaFileIds).then((res) => {
                    swal("Deleted Successfully", "", "success", {
                        buttons: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                closeModal: true,
                                className: "btn btn-outline-primary btn-sm swal-outline-primary-btn"
                            },
                        },
                    }).then((isClicked) => {
                        getImagesListFn();
                    });
                }).catch((error) => {
                    console.error(error);
                    swal({
                        title: "Something went wrong!",
                        text: "Please try again",
                        icon: "error",
                        closeOnClickOutside: false,
                        buttons: {
                            confirm: {
                                text: "OK",
                                visible: true,
                                className: "btn btn-outline-primary btn-sm swal-outline-primary-btn"
                            },
                        },
                    });
                });
            }
        });
    };
    const pageChange = (e, imagesListData) => {
        let parsedNum = parseInt(!!e.target ? e.target.innerText : e);
        setPage(parsedNum);
        let rear = parsedNum > 0 ? parsedNum * perPage : perPage;
        let front = rear - perPage;
        setImagesPaginatedData(imagesListData.slice(front, rear));
    };
    useEffect(() => {
        props.handleGalleryClose(openModal);
    }, [openModal]);
    useEffect(() => {
        setTimeout(function () {
            setShowInvalidImageFormatError(false);
        }, 3000);
    }, [showInvalidImageFormatError]);
    useEffect(() => {
        if (selectedTab === galleryNav.IMAGE_LIBRARY) {
            setLoadingImages(true);
            !!props.showModal && getImagesList(state.auth.org.org_token).then((res) => {
                setLoadingImages(false);
                setImagesList(res.data.images);
                setTotalItems(res.data.count);
                pageChange(1, res.data.images);
            }).catch((error) => {
                console.error(error);
            });
        }
        setOpenModal(props.showModal);
    }, [selectedTab, props.showModal]);
    return (
        <>
            <Modal className="fade gallery-modal" show={openModal} backdrop={true} keyboard={true}>
                <Modal.Body>

                    <div className="wigzo-gallery-nav">
                        <Modal.Title>Gallery</Modal.Title>
                        <ul id="galleryNavList">
                            {galleryNavData.map((navData, i) => {
                                return (
                                    <li key={i} className={`${navData.value === selectedTab ? 'activeTab' : ''} switch-tabs`} value={navData.value} onClick={() => switchTab(navData.value)}>
                                        {/* < img src={navData.iconUrl} style={{ marginRight: '10px' }} /> */}
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ 'display': navData.value === galleryNav.IMAGE_LIBRARY ? 'block' : 'none' }}>
                                            <g>
                                                <path d="M10.6875 7.875C11.0213 7.875 11.3475 7.77603 11.625 7.59061C11.9025 7.40518 12.1188 7.14163 12.2465 6.83328C12.3743 6.52493 12.4077 6.18563 12.3426 5.85829C12.2775 5.53094 12.1167 5.23026 11.8807 4.99426C11.6447 4.75826 11.3441 4.59754 11.0167 4.53243C10.6894 4.46731 10.3501 4.50073 10.0417 4.62845C9.73337 4.75618 9.46982 4.97247 9.28439 5.24998C9.09897 5.52748 9 5.85374 9 6.1875C9 6.63505 9.17779 7.06428 9.49426 7.38074C9.81072 7.69721 10.2399 7.875 10.6875 7.875ZM10.6875 5.625C10.7988 5.625 10.9075 5.65799 11 5.7198C11.0925 5.78161 11.1646 5.86946 11.2072 5.97224C11.2498 6.07502 11.2609 6.18812 11.2392 6.29724C11.2175 6.40635 11.1639 6.50658 11.0852 6.58525C11.0066 6.66392 10.9064 6.71749 10.7972 6.73919C10.6881 6.7609 10.575 6.74976 10.4722 6.70718C10.3695 6.66461 10.2816 6.59251 10.2198 6.50001C10.158 6.40751 10.125 6.29875 10.125 6.1875C10.125 6.03832 10.1843 5.89524 10.2898 5.78975C10.3952 5.68426 10.5383 5.625 10.6875 5.625Z" fill={selectedTab === galleryNav.IMAGE_LIBRARY ? '#4C02FF' : '#828284'} />
                                                <path d="M14.625 1.6875H3.375C3.07663 1.6875 2.79048 1.80603 2.5795 2.017C2.36853 2.22798 2.25 2.51413 2.25 2.8125V14.0625C2.25 14.3609 2.36853 14.647 2.5795 14.858C2.79048 15.069 3.07663 15.1875 3.375 15.1875H14.625C14.9234 15.1875 15.2095 15.069 15.4205 14.858C15.6315 14.647 15.75 14.3609 15.75 14.0625V2.8125C15.75 2.51413 15.6315 2.22798 15.4205 2.017C15.2095 1.80603 14.9234 1.6875 14.625 1.6875ZM14.625 14.0625H3.375V10.6875L6.1875 7.875L9.33187 11.0194C9.54266 11.2289 9.82779 11.3465 10.125 11.3465C10.4222 11.3465 10.7073 11.2289 10.9181 11.0194L11.8125 10.125L14.625 12.9375V14.0625ZM14.625 11.3456L12.6056 9.32625C12.3948 9.11672 12.1097 8.99911 11.8125 8.99911C11.5153 8.99911 11.2302 9.11672 11.0194 9.32625L10.125 10.2206L6.98063 7.07625C6.76984 6.86672 6.48471 6.74911 6.1875 6.74911C5.89029 6.74911 5.60516 6.86672 5.39438 7.07625L3.375 9.09562V2.8125H14.625V11.3456Z" fill={selectedTab === galleryNav.IMAGE_LIBRARY ? '#4C02FF' : '#828284'} />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_2_534">
                                                    <rect width="18" height="18" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ 'display': navData.value === galleryNav.UPLOAD ? 'block' : 'none' }}>
                                            <g>
                                                <path d="M6.1875 10.125L6.98062 10.9181L8.4375 9.46688V16.3125H9.5625V9.46688L11.0194 10.9181L11.8125 10.125L9 7.3125L6.1875 10.125Z" fill={selectedTab === galleryNav.UPLOAD ? '#4C02FF' : '#828284'} />
                                                <path d="M13.2188 12.375H12.9375V11.25H13.2188C13.8901 11.2769 14.5446 11.0359 15.0383 10.5802C15.532 10.1245 15.8244 9.49133 15.8513 8.82C15.8781 8.14867 15.6372 7.49417 15.1815 7.00048C14.7258 6.50679 14.0926 6.21436 13.4213 6.1875H12.9375L12.8813 5.72625C12.7565 4.77925 12.2917 3.90991 11.5735 3.28016C10.8553 2.6504 9.93273 2.30319 8.97754 2.30319C8.02236 2.30319 7.09974 2.6504 6.38155 3.28016C5.66337 3.90991 5.19859 4.77925 5.07379 5.72625L5.06254 6.1875H4.57879C3.90746 6.21436 3.2743 6.50679 2.81858 7.00048C2.36287 7.49417 2.12194 8.14867 2.14879 8.82C2.17565 9.49133 2.46808 10.1245 2.96177 10.5802C3.45546 11.0359 4.10996 11.2769 4.78129 11.25H5.06254V12.375H4.78129C3.87927 12.3693 3.0112 12.0303 2.34397 11.4233C1.67675 10.8163 1.25746 9.98402 1.16674 9.08656C1.07602 8.18909 1.32025 7.28976 1.8525 6.56148C2.38474 5.8332 3.16741 5.32737 4.05004 5.14125C4.29288 4.00867 4.91676 2.99361 5.81759 2.26545C6.71843 1.53729 7.84172 1.14008 9.00004 1.14008C10.1584 1.14008 11.2817 1.53729 12.1825 2.26545C13.0833 2.99361 13.7072 4.00867 13.95 5.14125C14.8327 5.32737 15.6153 5.8332 16.1476 6.56148C16.6798 7.28976 16.9241 8.18909 16.8333 9.08656C16.7426 9.98402 16.3233 10.8163 15.6561 11.4233C14.9889 12.0303 14.1208 12.3693 13.2188 12.375Z" fill={selectedTab === galleryNav.UPLOAD ? '#4C02FF' : '#828284'} />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_2_538">
                                                    <rect width="18" height="18" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        {navData.label}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="wigzo-gallery-content">
                        <Button
                            variant=""
                            className="close"
                            onClick={() => setOpenModal(false)}
                        >
                            <span>&times;</span>
                        </Button>
                        <div className="wigzo-gallery-images" id="wigzoGalleryImages" style={{ 'display': selectedTab === galleryNav.IMAGE_LIBRARY ? 'block' : 'none' }}>
                            <div className="wigzo-gallery-loader" style={{ 'display': !!loadingImages ? 'block' : 'none' }}>
                                <div className="text-center row" style={{ 'display': 'flex', 'alignItems': 'center', 'color': 'gray', }}>
                                    <i className="fa fa-spin">&#xf1ce;</i>
                                    <span>Loading Images....</span>
                                </div>
                            </div>
                            <ul style={{ 'display': !loadingImages ? 'block' : 'none' }}>
                                {imagesPaginatedData?.map((image, i) => {
                                    return (
                                        <li key={i}>
                                            <div className="asset-details">
                                                <a className="asset-thumbnail" style={{ 'backgroundImage': `url(${image.mediaFileUrl})` }} href={image.mediaFileUrl} target="_blank"></a>
                                                <div className="asset-info">
                                                    <h4>{image.fileName}</h4>
                                                    <p>{getImageFormat(image.fileName).toUpperCase()} — Created at {moment(image.creationDate).format("DD-MM-YYYY")} — Size: {((JSON.parse(image?.extraData)?.size || 0) / 1024).toFixed(2)}kb </p>
                                                </div>
                                            </div>
                                            <div className="asset-action-button">
                                                <button className="btn-primary btn-sm" onClick={() => selectAsset(image.mediaFileUrl, selectedTab, image.fileSize || 0, image.fileName)}
                                                    style={{ 'padding': '8px 15px', 'marginRight': '10px' }}>
                                                    Select
                                                </button>
                                                <div onClick={() => deleteImage(image.mediaFileId)} style={{ cursor: 'pointer' }}><img src={deleteIcon} alt="Delete"></img></div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="wigzo-gallery-upload" id="wigzoGalleryUpload" style={{ 'display': selectedTab === galleryNav.UPLOAD ? 'flex' : 'none', flexDirection: 'column' }}>
                            <div style={{ 'width': '75%', 'height': '200px' }}>
                                <Drop
                                    // getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus}
                                    // onSubmit={() => handleSubmit}
                                    inputContent="Drop files here or click to upload image (.jpg/jpeg or .png)"
                                    maxFiles={5}
                                    accept="image/jpeg,image/jpg,image/png"
                                />
                            </div>
                            <div id="invalidImageFormatError" style={{ opacity: showInvalidImageFormatError ? '1' : '0', transition: 'opacity 1.5s linear', color: 'red' }}>Invalid image format! Accepted image formats are: png, jpg, jpeg</div>
                        </div>
                        {
                            !loadingImages && totalItems >= perPage && selectedTab === galleryNav.IMAGE_LIBRARY &&
                            <div
                                className="gallery-pagination">
                                <Pagination
                                    totalRecords={totalItems}
                                    pageLimit={perPage}
                                    pageNeighbours={8}
                                    currentPage={page}
                                    onPageChanged={(e) => pageChange(e, imagesList)}
                                ></Pagination>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default GalleryModal;
