import React from "react";
import { useState } from "react";
import ChannelV2 from "./ChannelV2";
import ScheduleCampaignV2 from "./ScheduleCampaignV2";
import MessageV2 from "./MessageV2";
import AudienceV2 from "./AudienceV2";
import { Stepper, Step, StepLabel } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { useSelector } from "react-redux";
import { saveCampaign } from "../../../services/GetService";
import swal from "sweetalert";
import "../../../css/broadcast.css";
import "../../../css/journey-css.css";
import { useHistory, useLocation } from "react-router-dom";
// import broadcastFormData from "./FormData";
import validateSchema from "./validationSchema";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { makeStyles } from "@material-ui/core";
function BroadcastCreateV2(props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();
  const state = useSelector((state) => state);
  const [steps, setSteps] = useState([
    { label: "Channel", isCompleted: false },
    { label: "Audience", isCompleted: false },
    { label: "Message", isCompleted: false },
    { label: "Schedule Campaign", isCompleted: false },
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;
  const [isValid, setIsValid] = useState(false);
  const currentValidationSchema = validateSchema[activeStep];
  let [formData, setFormData] = useState({
    selectedChannel: "",
    campaignName: "",
    selectedTemp: "",
    selectedTempName: "",
    status: "",
    repeatAfterDays: 1,
    message: "",
    variables: "[]",
    dropdownVars: {},
    replacedVariables: "{}",
    isUnicode: false,
    crmName: "",
    coupon: "",
    mediaUrl: "",
    targetedAudience: {
      segmentType: "sendToAll",
      selectedSegment: null,
      selectedSegmentName: "",
      estimateReach: 0
    },
    removeDuplicates: true,
    mapped_headers: [],
    csvDetails: {},
    isArabianText: false,
    addToContactList: false,
    isScheduled: false,
    scheduleAt: Math.trunc(new Date(new Date()).getTime() / 1000),
    repeatStopDateTime: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000),
    expireAfter: Math.trunc((new Date().getTime() + (30 * 60 * 1000)) / 1000),
    replacedButtonUrl: "[]",
    replacedAttachment: "",
    broadcastTemplateUuid: "",
    broadcastType: "BROADCAST",
    predefinedSegmentData: {},
    testSizeRatio: 50,
  });
  let handleButtonState = (state) => {
    setIsValid(state);
  };
  function removeDateFromUrl(url) {
    let replacedUrl = !!url && url.search('http') >= 0 ? new URL(url) : url;
    replacedUrl = !!replacedUrl && !!replacedUrl.href ? replacedUrl.href.replace(replacedUrl.search, '') : replacedUrl;
    return replacedUrl;
  }
  function _renderStepContent(step, formikContext) {
    switch (step) {
      case 0:
        return (
          <ChannelV2
            formField={formData}
            handleSubmission={handleSubmission}
            steps={steps}
            disabled={disabled}
            nextStyle={nextStyle}
            activeStep={activeStep}
            formData={formData}
            currentValidationSchema={currentValidationSchema}
            handleButtonState={handleButtonState}
            isCompleted={steps[activeStep].isCompleted}
          />
        );
      case 1:
        return (
          <AudienceV2
            formField={formData}
            handleSubmission={handleSubmission}
            steps={steps}
            activeStep={activeStep}
            isCompleted={steps[activeStep].isCompleted}
            currentValidationSchema={currentValidationSchema}
            handleButtonState={handleButtonState}
            disabled={disabled}
            nextStyle={nextStyle}
            formData={formData}
          />
        );
      case 2:
        return (
          <MessageV2
            formField={formData}
            handleSubmission={handleSubmission}
            disabled={disabled}
            nextStyle={nextStyle}
            steps={steps}
            activeStep={activeStep}
            isCompleted={steps[activeStep].isCompleted}
            currentValidationSchema={currentValidationSchema}
            handleButtonState={handleButtonState}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 3:
        return (
          <ScheduleCampaignV2
            formField={formData}
            handleSubmission={handleSubmission}
            disabled={disabled}
            nextStyle={nextStyle}
            steps={steps}
            activeStep={activeStep}
            isCompleted={steps[activeStep].isCompleted}
            currentValidationSchema={currentValidationSchema}
            handleButtonState={handleButtonState}
            formData={formData}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }
  let prevStyle = {
    position: "absolute",
    right: "8.5rem",
    top: "9rem",
    background: "#fff",
    borderWidth: "0px",
    color: "#4C02FF",
    borderRadius: "5px",
    fontSize: "0.875rem",
    fontWeight: "600",
    padding: "0.625rem 1rem",
    float: "right",
    border: "1px solid #4C02FF",
    marginRight: "1rem",
  };
  if (activeStep === 0) {
    prevStyle.display = "none";
  }
  let handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  let nextStyle = {
    cursor: "pointer",
    border: "1px solid #4C02FF",
    position: "absolute",
    right: "4.5rem",
    top: "9rem",
    background: "#4C02FF",
    borderWidth: "0px",
    color: "#fff",
    borderRadius: "5px",
    fontSize: "0.875rem",
    fontWeight: "600",
    padding: "0.625rem 1rem",
    float: "right",
  };
  let disabled = {
    borderWidth: "0px",
    borderRadius: "5px",
    fontSize: "0.875rem",
    fontWeight: "600",
    padding: "0.625rem 1rem",
    position: "absolute",
    right: "4.5rem",
    top: "9rem",
    border: "1px solid #c4c4c4",
    background: "#C4C4C4",
    color: "#fff",
  };
  const muiTheme = getMuiTheme({
    stepper: {
      iconColor: "#5FBD62",
      root: {
        width: "90%",
      },

      connectorActive: {
        "& $line": {
          borderColor: "#FBD62",
        },
      },
      connectorCompleted: {
        "& $line": {
          borderColor: "#5FBD62",
        },
      },
      line: {
        color: "green"
      },
    },
    active: {
      "& $line": {
        borderColor: "green",
      },
    },
    completed: {
      "& $line": {
        borderColor: "green",
      },
    },

    // or logic to change color
  });

  const useStyle = makeStyles((theme) => ({
    root: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3)
      }
    }
  }));

  const showSwal = (data) => {
    swal(`${data.status === 'DRAFT' ? 'Send' : 'Scheduled'} Successfully`, "", "success", {
      button: {
        text: "Go back to campaign overview",
        value: true,
        visible: true,
        closeModal: true,
        className: "btn btn-outline-primary btn-sm swal-outline-primary-btn"
      }
    }).then((isClicked) => {
      !!searchParams.has('wib') ? history.push('/broadcast/broadcast-overview?wib=1') : history.push("/broadcast/overview");
    });
  };
  function saveBroadcast(data) {
    swal({
      title: "Are you sure?",
      text: `Do you want to ${data.status === 'DRAFT' ? 'send' : 'schedule'} this campaign?`,
      icon: "warning",
      closeOnClickOutside: true,
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
          className: "btn btn-light btn-sm"
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          closeModal: false,
          className: "btn btn-primary btn-sm swal-primary-btn"
        },
      },
    }).then((isConfirm) => {
      if (!!isConfirm) {
        delete data.dropdownVars;
        data.repeatStopDateTime = !data.repeatStopDateTime ? new Date(Date.now() + 10 * 24 * 60 * 60 * 1000) : data.repeatStopDateTime;
        data.campaignName.trim();
        data.replacedAttachment = removeDateFromUrl(data.replacedAttachment);
        data.mediaUrl = data.replacedAttachment;
        saveCampaign(data, state.auth.org.org_token)
          .then((res) => {
            !!res && showSwal(data);
          })
          .catch((error) => {
            swal({
              title: "Something went wrong!",
              text: "Please try again",
              icon: "error",
              closeOnClickOutside: false,
              buttons: {
                confirm: {
                  text: "OK",
                  visible: true,
                  className: "btn btn-outline-primary btn-sm swal-outline-primary-btn"
                },
              },
            });
          });
      }
    });

    //
  }
  let getExtraData = () => {
    return {
      newCustomAudienceData: {
        name: "",
        subtype: "CUSTOM",
        description: "",
        customer_file_source: "USER_PROVIDED_ONLY",
      },
      criteria: {
        criteria: {
          criteria_type: "intermediate",
          criteria_operation: "and",
          criteria: [],
        },
        fields: ["email"],
      },
      type: "predefined",
      segmentId: formData.targetedAudience.id,
      dates: {
        startDate: "2020-02-26",
        endDate: "2020-03-04",
      },
    };
  };

  function handleSubmission(dataFromChild) {
    // data = {...data}
    setFormData({ ...formData, ...dataFromChild });
    let updatedFormData = { ...formData };
    updatedFormData = { ...updatedFormData, ...dataFromChild };
    updatedFormData.mediaUrl = updatedFormData.replacedAttachment;
    let extraData;
    !!isLastStep && (extraData = getExtraData(updatedFormData));
    !!isLastStep && (updatedFormData = { ...updatedFormData, ...extraData });
    setFormData(updatedFormData);
    if (isLastStep) {
      saveBroadcast(updatedFormData);
    } else {
      setActiveStep(activeStep + 1);
      let updatedStep = [...steps];
      updatedStep[activeStep].isCompleted = true;
      setSteps(updatedStep);

      setSteps(updatedStep);
      setIsValid(false);
    }
  }

  return (
    <>
      <div className="content-body broadcast-form-horizontal step-form-horizontal" style={!!searchParams.has('wib') ? { marginLeft: '3rem', paddingTop: '2rem' } : {
        paddingRight: "4.5rem",
        paddingLeft: "2rem"
      }}>
        <div className="whatsappTemp-header d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <a className="mdi mdi-arrow-left"
              onClick={() => !!searchParams.has('wib') ? history.push('/broadcast/broadcast-overview?wib=1') : history.push('/broadcast/broadcast-overview')} style={{ 'cursor': 'pointer', marginRight: '15px', display: activeStep > 0 ? 'none' : 'block' }}></a>
            <h3>Create Campaign</h3>
            {/* <p>
              Easily design and send broadcast to reach customers at the right
              place
            </p> */}
          </div>
        </div>
        {/* <BroadcastMultiStep handlePrevious={handlePrevious} activeStep={activeStep} showNavigation={true} prevStyle = {prevStyle} nextStyle = {nextStyle} steps={steps} /> */}
        <MuiThemeProvider muiTheme={muiTheme}>
          <Stepper
            style={{ width: "75%", }}
            alternativeLabel
            activeStep={activeStep}
          >
            {steps.map((step, i) => (
              <Step style={{ display: "flex" }} key={i}>
                <StepLabel

                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </MuiThemeProvider>
        {_renderStepContent(activeStep)}
        {/* <button disabled={!isValid} style={isValid ? nextStyle : disabled} form={steps[activeStep].label.split(" ").join("")}> {isLastStep ? 'Send' : 'Next'}</button> */}
        <button type="button" onClick={handlePrevious} style={prevStyle}>
          Back
        </button>
      </div>
    </>
  );
}
// const mapStateToProps = (state) => {
//    return { ...state }
// }

export default BroadcastCreateV2;
