//empty state placeholder
export const emptyState =  <svg width="120" height="150" viewBox="0 0 89 112" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M42.9705 20.3848L27.2607 61.1727L73.7172 61.5364L58.3438 20.3848H42.9705Z" fill="#989898" />
<path d="M50.4689 22.5235C51.7563 22.5235 52.8001 21.4811 52.8001 20.1953C52.8001 18.9096 51.7563 17.8672 50.4689 17.8672C49.1814 17.8672 48.1377 18.9096 48.1377 20.1953C48.1377 21.4811 49.1814 22.5235 50.4689 22.5235Z" fill="white" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.1191 15.7929C52.0706 15.3714 51.9396 13.8574 51.9396 13.8574H49.2934C49.2934 13.8574 49.1673 15.3676 48.1139 15.7929C47.6023 15.9981 46.4556 16.2435 45.3669 16.6927C44.6468 16.9899 44.0311 17.4936 43.5981 18.1405C43.165 18.7872 42.9336 19.5478 42.9336 20.3259V20.3398H58.2994V20.3259C58.2992 19.5478 58.068 18.7872 57.6349 18.1405C57.2017 17.4936 56.5861 16.9899 55.8661 16.6927C54.7774 16.2435 53.6307 15.9993 53.1191 15.7929Z" fill="#868A91" />
<path d="M51.6579 13.498H49.5762V13.858H51.6579V13.498Z" fill="#61656D" />
<path d="M51.3279 12.7715H49.9053V13.4976H51.3279V12.7715Z" fill="#61656D" />
<path d="M51.6579 13.498H49.5762V13.858H51.6579V13.498Z" fill="#61656D" />
<path d="M51.3279 12.7715H49.9053V13.4976H51.3279V12.7715Z" fill="#61656D" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M50.7432 12.7709H50.4912V0L50.7432 0.0188762V12.7709Z" fill="#61656D" />
<path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M58.3002 20.3259C58.3 19.5478 58.0688 18.7872 57.6357 18.1405C57.2025 17.4936 56.5869 16.9899 55.8669 16.6927C54.7782 16.2435 53.6315 15.9993 53.1199 15.7929C52.0714 15.3714 51.9404 13.8574 51.9404 13.8574H51.3759L51.328 13.8662C51.328 13.8662 51.1617 15.7275 52.7998 16.504C53.5155 16.8425 54.9155 17.4679 55.4145 18.0821C55.8707 18.6446 55.9261 19.3846 55.9261 20.3398H58.3065L58.3002 20.3259Z" fill="black" />
<path d="M7.46686 112H73.5999L82.6389 63.1268C82.678 62.9109 82.6704 62.6886 82.6149 62.4762C82.5595 62.2636 82.4574 62.0659 82.3163 61.897C82.1764 61.7283 82 61.5924 81.8009 61.4991C81.6018 61.4059 81.3838 61.3574 81.1646 61.3574H15.0313L5.99254 110.239C5.9536 110.454 5.96255 110.675 6.01875 110.887C6.07482 111.099 6.17677 111.296 6.3174 111.463C6.45802 111.632 6.63381 111.766 6.8324 111.859C7.031 111.953 7.2476 112 7.46686 112Z" fill="#A5A5A5" />
<path d="M73.5995 111.999H6.99648C6.62954 111.999 6.27519 111.866 6.00087 111.622C5.72654 111.379 5.55113 111.043 5.50829 110.679L0.010438 64.5663C-0.0145121 64.3564 0.00539532 64.1435 0.0687788 63.9418C0.132162 63.74 0.237632 63.5539 0.378261 63.3958C0.518889 63.2377 0.691401 63.111 0.88445 63.0243C1.0775 62.9376 1.2868 62.8927 1.49863 62.8926H66.4144C66.7812 62.8925 67.1354 63.0269 67.4096 63.2701C67.6839 63.5135 67.8593 63.8489 67.9026 64.2127L73.5995 111.999Z" fill="#C4C4C4" />
<path d="M14.9795 61.6391L16.0191 56.7538C16.0927 56.4076 16.283 56.0971 16.5582 55.8742C16.8334 55.6514 17.1769 55.5295 17.5312 55.5293H27.6247C27.9678 55.5294 28.3012 55.6433 28.5727 55.853C28.844 56.0628 29.038 56.3566 29.1242 56.6882L30.3982 61.6089L14.9795 61.6391Z" fill="#A5A5A5" />
<g opacity="0.4">
  <path opacity="0.4" d="M44.331 82.2854L41.4794 79.4375L35.7736 85.1358L30.0704 79.4375L27.22 82.2854L32.9233 87.9825L27.2188 93.6782L30.0704 96.5261L35.7749 90.8304L41.4794 96.5287L44.331 93.6845L38.6253 87.9837L44.331 82.2854Z" fill="black" />
</g>
<path d="M88.2528 108.679C88.2629 108.679 88.273 108.677 88.2818 108.673C88.2906 108.668 88.2982 108.662 88.3045 108.654C88.3171 108.638 88.3247 108.616 88.3247 108.595C88.3247 108.575 88.3171 108.553 88.3045 108.537L87.7601 107.954C87.7425 107.942 87.7223 107.936 87.7021 107.936C87.6807 107.936 87.6606 107.942 87.6442 107.954C87.6316 107.971 87.624 107.992 87.624 108.012C87.624 108.034 87.6316 108.055 87.6442 108.071L88.1885 108.654C88.2062 108.669 88.2289 108.679 88.2528 108.679Z" fill="white" />
<path d="M87.8312 109.016C87.8413 109.016 87.8514 109.014 87.8602 109.009C87.869 109.005 87.8766 108.998 87.8829 108.99C87.8955 108.974 87.9018 108.954 87.9018 108.932C87.9018 108.911 87.8955 108.891 87.8829 108.874L87.3385 108.292C87.3221 108.279 87.3007 108.271 87.2806 108.271C87.2591 108.271 87.2377 108.279 87.2213 108.292C87.2087 108.308 87.2012 108.328 87.2012 108.35C87.2012 108.371 87.2087 108.391 87.2213 108.407L87.7657 108.99C87.7833 109.006 87.8073 109.016 87.8312 109.016Z" fill="white" />
<path d="M87.3962 109.341C87.4063 109.341 87.4164 109.338 87.4252 109.334C87.434 109.329 87.4416 109.323 87.4479 109.314C87.4605 109.298 87.4681 109.278 87.4681 109.256C87.4681 109.235 87.4605 109.215 87.4479 109.198L86.9035 108.616C86.8884 108.601 86.867 108.592 86.8456 108.592C86.8241 108.592 86.8027 108.601 86.7876 108.616C86.7725 108.631 86.7637 108.652 86.7637 108.674C86.7637 108.695 86.7725 108.716 86.7876 108.731L87.332 109.314C87.3446 109.341 87.3572 109.341 87.3962 109.341Z" fill="white" />
<path d="M55.9774 32.7496C55.3364 31.6582 54.2953 30.8552 53.0799 30.5064C50.2491 29.733 47.1054 30.1069 45.5721 31.7817C43.7413 33.1235 43.4336 36.4884 45.967 36.4884C47.5362 36.4884 47.9311 33.3489 50.9773 34.0864C51.2491 34.1734 51.4952 34.3307 51.6901 34.5427C51.885 34.7547 52.0234 35.0144 52.085 35.2951C52.5978 37.1388 50.2081 38.4244 48.526 40.4166C48.2593 40.7291 48.09 41.1116 48.0336 41.5188C47.9772 41.9255 48.0388 42.3398 48.2132 42.7121C48.3824 43.085 48.6593 43.4005 49.008 43.6217C49.3516 43.843 49.7567 43.9608 50.167 43.9608C50.5978 43.9592 51.0183 43.8394 51.3875 43.613C51.7516 43.3872 52.0491 43.0645 52.244 42.6804C53.7363 39.6945 58.7108 37.2976 55.9774 32.7496Z" fill="#B2B6BB" />
<path d="M49.8747 45.9425C49.3773 45.8432 48.8645 45.9056 48.4081 46.1197C47.9568 46.3343 47.5772 46.6892 47.3413 47.1328C47.1003 47.5763 47.0131 48.0849 47.0849 48.5827C47.1618 49.0805 47.3926 49.542 47.7465 49.8984C48.1055 50.2549 48.567 50.4874 49.0644 50.5622C49.5619 50.6365 50.0696 50.5489 50.5158 50.3123C50.9619 50.0751 51.3158 49.7018 51.5312 49.247C51.7466 48.7922 51.8132 48.28 51.7158 47.7863C51.6235 47.332 51.4029 46.9146 51.0747 46.5863C50.7465 46.2585 50.326 46.0342 49.8747 45.9425Z" fill="#B2B6BB" />
<g opacity="0.3">
  <path opacity="0.3" d="M59.4761 51.3424C59.4469 51.3432 59.4183 51.3335 59.3952 51.3154C59.3723 51.2972 59.3563 51.2717 59.3501 51.243L53.0496 22.1725C53.0426 22.1392 53.0492 22.1045 53.0678 22.0759C53.0865 22.0473 53.1157 22.0273 53.1491 22.0203C53.1826 22.0133 53.2174 22.0199 53.246 22.0385C53.2746 22.0571 53.2947 22.0863 53.3016 22.1197L59.6021 51.1901C59.6057 51.2064 59.6059 51.2231 59.603 51.2395C59.6 51.2557 59.5938 51.2713 59.5849 51.2852C59.5758 51.2991 59.5641 51.3111 59.5505 51.3205C59.5367 51.33 59.5214 51.3365 59.5051 51.3399L59.4761 51.3424Z" fill="white" />
</g>
<g opacity="0.3">
  <path opacity="0.3" d="M41.8353 51.3419H41.8089C41.7926 51.3385 41.7773 51.3318 41.7635 51.3224C41.7499 51.3131 41.7382 51.301 41.7292 51.2872C41.7202 51.2732 41.714 51.2577 41.711 51.2414C41.7081 51.2251 41.7083 51.2084 41.7119 51.1922L48.0124 22.1217C48.0158 22.1052 48.0226 22.0895 48.0321 22.0755C48.0416 22.0616 48.0539 22.0497 48.068 22.0404C48.0821 22.0312 48.098 22.0248 48.1146 22.0218C48.1312 22.0186 48.1482 22.0188 48.1649 22.0223C48.1814 22.0257 48.1971 22.0325 48.211 22.0419C48.225 22.0515 48.2369 22.0637 48.2461 22.0778C48.2555 22.092 48.2618 22.1077 48.2649 22.1244C48.2681 22.141 48.2678 22.1581 48.2644 22.1746L41.9639 51.245C41.9571 51.2736 41.9406 51.2989 41.9171 51.3166C41.8937 51.3344 41.8647 51.3433 41.8353 51.3419Z" fill="white" />
</g>
</svg>

//eye Icon SVG
export  const eyeIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.99999 10.5C8.78888 10.5 9.45832 10.225 10.0083 9.67502C10.5583 9.12502 10.8333 8.45558 10.8333 7.66669C10.8333 6.8778 10.5583 6.20835 10.0083 5.65835C9.45832 5.10835 8.78888 4.83335 7.99999 4.83335C7.2111 4.83335 6.54166 5.10835 5.99166 5.65835C5.44166 6.20835 5.16666 6.8778 5.16666 7.66669C5.16666 8.45558 5.44166 9.12502 5.99166 9.67502C6.54166 10.225 7.2111 10.5 7.99999 10.5ZM7.99999 9.53335C7.47777 9.53335 7.0361 9.3528 6.67499 8.99169C6.31388 8.63058 6.13332 8.18891 6.13332 7.66669C6.13332 7.14446 6.31388 6.7028 6.67499 6.34169C7.0361 5.98058 7.47777 5.80002 7.99999 5.80002C8.52221 5.80002 8.96388 5.98058 9.32499 6.34169C9.6861 6.7028 9.86666 7.14446 9.86666 7.66669C9.86666 8.18891 9.6861 8.63058 9.32499 8.99169C8.96388 9.3528 8.52221 9.53335 7.99999 9.53335ZM7.99999 12.6667C6.37777 12.6667 4.9111 12.2056 3.59999 11.2834C2.28888 10.3611 1.3111 9.15558 0.666656 7.66669C1.3111 6.1778 2.28888 4.97224 3.59999 4.05002C4.9111 3.1278 6.37777 2.66669 7.99999 2.66669C9.62221 2.66669 11.0889 3.1278 12.4 4.05002C13.7111 4.97224 14.6889 6.1778 15.3333 7.66669C14.6889 9.15558 13.7111 10.3611 12.4 11.2834C11.0889 12.2056 9.62221 12.6667 7.99999 12.6667ZM7.99999 11.6667C9.34443 11.6667 10.5805 11.3028 11.7083 10.575C12.8361 9.84724 13.6944 8.8778 14.2833 7.66669C13.6944 6.45558 12.8361 5.48613 11.7083 4.75835C10.5805 4.03058 9.34443 3.66669 7.99999 3.66669C6.65555 3.66669 5.41943 4.03058 4.29166 4.75835C3.16388 5.48613 2.29999 6.45558 1.69999 7.66669C2.29999 8.8778 3.16388 9.84724 4.29166 10.575C5.41943 11.3028 6.65555 11.6667 7.99999 11.6667Z" fill="#4C02FF" />
</svg>

//whatsapp icon
export const whatsapp = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.7983 11.4924C14.7593 11.4737 13.3013 10.7557 13.0422 10.6624C12.9364 10.6244 12.8231 10.5874 12.7026 10.5874C12.5057 10.5874 12.3403 10.6855 12.2115 10.8782C12.0658 11.0947 11.6249 11.6101 11.4887 11.7641C11.4709 11.7844 11.4466 11.8087 11.4321 11.8087C11.419 11.8087 11.1934 11.7157 11.1251 11.6861C9.56105 11.0066 8.37385 9.3728 8.21105 9.0973C8.18775 9.0577 8.18675 9.0397 8.18665 9.0397C8.19235 9.0187 8.24495 8.966 8.27205 8.9388C8.35145 8.8602 8.43755 8.7566 8.52075 8.6565C8.56015 8.609 8.59965 8.5615 8.63845 8.5166C8.75915 8.3761 8.81295 8.267 8.87535 8.1407L8.90795 8.075C9.06015 7.7726 8.93015 7.5174 8.88815 7.435C8.85365 7.3661 8.23805 5.8802 8.17255 5.7241C8.01515 5.3474 7.80715 5.172 7.51815 5.172C7.49135 5.172 7.51815 5.172 7.40565 5.1768C7.26875 5.1826 6.52295 5.2807 6.19325 5.4886C5.84355 5.7091 5.25195 6.4118 5.25195 7.6476C5.25195 8.7599 5.95785 9.8101 6.26085 10.2095C6.26845 10.2196 6.28225 10.24 6.30235 10.2694C7.46285 11.9643 8.90975 13.2204 10.3764 13.8063C11.7884 14.3703 12.457 14.4355 12.8371 14.4355C12.8372 14.4355 12.8372 14.4355 12.8372 14.4355C12.9969 14.4355 13.1248 14.423 13.2376 14.4119L13.3091 14.4051C13.7969 14.3618 14.8689 13.8064 15.1127 13.1288C15.3048 12.5951 15.3554 12.012 15.2277 11.8004C15.1401 11.6565 14.9893 11.5841 14.7983 11.4924Z" fill="#5FBD62"/>
<path d="M10.1775 0C4.76092 0 0.354128 4.3736 0.354128 9.7495C0.354128 11.4883 0.819527 13.1903 1.70093 14.6799L0.0137288 19.6568C-0.0176712 19.7496 0.00572814 19.8522 0.074328 19.9221C0.123928 19.9727 0.191128 20 0.259728 20C0.286028 20 0.312528 19.996 0.338328 19.9878L5.52802 18.3387C6.94812 19.0974 8.55322 19.498 10.1776 19.498C15.5937 19.498 20 15.1248 20 9.7495C20 4.3736 15.5937 0 10.1775 0ZM10.1775 17.4671C8.64902 17.4671 7.16852 17.0257 5.89592 16.1907C5.85322 16.1626 5.80352 16.1481 5.75352 16.1481C5.72712 16.1481 5.70062 16.1522 5.67482 16.1604L3.07512 16.9867L3.91432 14.5108C3.94152 14.4307 3.92792 14.3423 3.87792 14.274C2.90882 12.9498 2.39652 11.3854 2.39652 9.7495C2.39652 5.4935 5.88712 2.0309 10.1774 2.0309C14.4673 2.0309 17.9574 5.4935 17.9574 9.7495C17.9575 14.005 14.4674 17.4671 10.1775 17.4671Z" fill="#5FBD62"/>
</svg>

//webpush icon
export const webpush = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_12_1121)">
<path d="M9.99997 0C11.0622 0 11.9366 0.804481 12.047 1.83742C12.7946 1.0667 13.8414 0.588235 15 0.588235C17.2741 0.588235 19.1176 2.43177 19.1176 4.70588C19.1176 6.57096 17.8776 8.14643 16.1771 8.65283L16.1764 13.5294H16.4706C17.6076 13.5294 18.5294 14.4512 18.5294 15.5882C18.5294 16.7253 17.6076 17.6471 16.4706 17.6471L13.0343 17.6475C12.5497 19.0181 11.2424 20 9.70585 20C8.16928 20 6.86204 19.0181 6.37742 17.6475L2.94115 17.6471C1.80409 17.6471 0.882324 16.7253 0.882324 15.5882C0.882324 14.4512 1.80409 13.5294 2.94115 13.5294H3.23527V8.23529C3.23527 5.49178 4.94271 3.14705 7.35294 2.2058L7.35291 2.05882C7.35291 0.959669 8.21425 0.0616869 9.29877 0.00304278L9.41174 0H9.99997ZM11.7436 17.6477H7.66807C8.075 18.3507 8.8352 18.8235 9.70585 18.8235C10.5765 18.8235 11.3367 18.3507 11.7436 17.6477ZM16.4706 14.7059H2.94115C2.45384 14.7059 2.05879 15.1009 2.05879 15.5882C2.05879 16.0755 2.45384 16.4706 2.94115 16.4706H16.4706C16.9579 16.4706 17.3529 16.0755 17.3529 15.5882C17.3529 15.1009 16.9579 14.7059 16.4706 14.7059ZM9.70585 2.94118C6.83421 2.94118 4.49657 5.22754 4.41399 8.0792L4.41174 8.23529V13.5294H15V8.82353C12.7259 8.82353 10.8823 6.98 10.8823 4.70588C10.8823 4.15599 10.9901 3.63128 11.1857 3.15171C10.7644 3.02829 10.3205 2.95671 9.86195 2.94343L9.70585 2.94118ZM15 1.76471C13.3756 1.76471 12.0588 3.08152 12.0588 4.70588C12.0588 6.33025 13.3756 7.64706 15 7.64706C16.6243 7.64706 17.9411 6.33025 17.9411 4.70588C17.9411 3.08152 16.6243 1.76471 15 1.76471ZM9.99997 1.17647H9.41174C8.98998 1.17647 8.63733 1.47238 8.55009 1.86792C8.92571 1.79996 9.31166 1.76471 9.70585 1.76471C10.1 1.76471 10.486 1.79995 10.8607 1.86747C10.7744 1.47238 10.4217 1.17647 9.99997 1.17647Z" fill="#4C02FF"/>
</g>
<defs>
<clipPath id="clip0_12_1121">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

//email icon
export const email = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.557 3.21094H2.44296C1.09597 3.21094 0 4.30691 0 5.65389V14.3457C0 15.6927 1.09597 16.7886 2.44296 16.7886H17.557C18.904 16.7886 20 15.6927 20 14.3457V5.65416C20.0003 4.30691 18.9043 3.21094 17.557 3.21094ZM16.3633 4.80469L10 8.90435L3.63668 4.80469H16.3633ZM17.557 15.1952H2.44296C1.97466 15.1952 1.59375 14.814 1.59375 14.346V5.69932L9.50833 10.7887C9.51895 10.7953 9.53037 10.7999 9.54126 10.806C9.55269 10.8123 9.56437 10.8184 9.57606 10.8243C9.63742 10.8559 9.70064 10.8814 9.76519 10.8981C9.77183 10.9 9.77847 10.9008 9.78511 10.9024C9.85603 10.9191 9.92775 10.9295 9.99947 10.9295C9.99973 10.9295 10 10.9295 10 10.9295C10.0005 10.9295 10.0008 10.9295 10.0011 10.9295C10.0728 10.9295 10.1445 10.9194 10.2154 10.9024C10.2221 10.9008 10.2287 10.9 10.2353 10.8981C10.2999 10.8814 10.3628 10.8559 10.4245 10.8243C10.4362 10.8184 10.4478 10.8123 10.4593 10.806C10.4702 10.7999 10.4816 10.7953 10.4922 10.7887L18.4068 5.69932V14.3457C18.4065 14.814 18.0253 15.1952 17.557 15.1952Z" fill="#FB3FF5"/>
</svg>

//onsite icon
export const onsite = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 2.5V3.75H5C4.66848 3.75 4.35054 3.8817 4.11612 4.11612C3.8817 4.35054 3.75 4.66848 3.75 5V15C3.75 15.3315 3.8817 15.6495 4.11612 15.8839C4.35054 16.1183 4.66848 16.25 5 16.25H15C15.3315 16.25 15.6495 16.1183 15.8839 15.8839C16.1183 15.6495 16.25 15.3315 16.25 15V10H17.5V15C17.5 15.663 17.2366 16.2989 16.7678 16.7678C16.2989 17.2366 15.663 17.5 15 17.5H5C4.33696 17.5 3.70107 17.2366 3.23223 16.7678C2.76339 16.2989 2.5 15.663 2.5 15V5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H10Z" fill="#E87C01"/>
<path d="M15 7.5C15.663 7.5 16.2989 7.23661 16.7678 6.76777C17.2366 6.29893 17.5 5.66304 17.5 5C17.5 4.33696 17.2366 3.70107 16.7678 3.23223C16.2989 2.76339 15.663 2.5 15 2.5C14.337 2.5 13.7011 2.76339 13.2322 3.23223C12.7634 3.70107 12.5 4.33696 12.5 5C12.5 5.66304 12.7634 6.29893 13.2322 6.76777C13.7011 7.23661 14.337 7.5 15 7.5ZM15 8.75C14.5075 8.75 14.0199 8.653 13.5649 8.46455C13.11 8.27609 12.6966 7.99987 12.3483 7.65165C12.0001 7.30343 11.7239 6.89003 11.5355 6.43506C11.347 5.98009 11.25 5.49246 11.25 5C11.25 4.50754 11.347 4.01991 11.5355 3.56494C11.7239 3.10997 12.0001 2.69657 12.3483 2.34835C12.6966 2.00013 13.11 1.72391 13.5649 1.53545C14.0199 1.347 14.5075 1.25 15 1.25C15.9946 1.25 16.9484 1.64509 17.6517 2.34835C18.3549 3.05161 18.75 4.00544 18.75 5C18.75 5.99456 18.3549 6.94839 17.6517 7.65165C16.9484 8.35491 15.9946 8.75 15 8.75Z" fill="#E87C01"/>
</svg>

//sms icon
export const SMS = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.5 16.9079V4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H15.8333C16.7538 2.5 17.5 3.24619 17.5 4.16667V12.5C17.5 13.4205 16.7538 14.1667 15.8333 14.1667H6.63437C6.12807 14.1667 5.64922 14.3968 5.33292 14.7922L3.39043 17.2202C3.09518 17.5893 2.5 17.3806 2.5 16.9079Z" stroke="#00A891" stroke-width="1.5"/>
</svg>
