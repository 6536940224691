import React, { useEffect, useState } from "react";
import { getWalletBalance, saveWalletBalanceInLocalStorage, setWalletBalance } from "../../../../services/AuthService";
import { useDispatch, useSelector, connect } from "react-redux";
import { walletDetailsAction } from "../../../../store/actions/AuthActions";

const WalletDetails = (props) => {
    var dispatch = useDispatch();
    //var state = useSelector((state) => state);
    // const [walletDetails, setWalletDetails] = useState({});
    const updateWalletBalance = () => {
        getWalletBalance(props.auth.org.org_token).then((res) => {
            dispatch(walletDetailsAction(res.data));
            saveWalletBalanceInLocalStorage(res.data);
            // setWalletDetails(res.data);
        });
    };
    useEffect(() => {
        console.log('wallet balance updated');
    }, [props?.auth?.walletDetails?.balance]);
    return (
        <>
            <div className="wallet-balance">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect width="24" height="24" fill="white" />
                    <path d="M16.3 13.6C16.7167 13.6 17.0875 13.4375 17.4125 13.1125C17.7375 12.7875 17.9 12.4083 17.9 11.975C17.9 11.5583 17.7375 11.1917 17.4125 10.875C17.0875 10.5583 16.7167 10.4 16.3 10.4C15.8833 10.4 15.5125 10.5583 15.1875 10.875C14.8625 11.1917 14.7 11.5583 14.7 11.975C14.7 12.4083 14.8625 12.7875 15.1875 13.1125C15.5125 13.4375 15.8833 13.6 16.3 13.6ZM13.45 16.675C12.8833 16.675 12.4333 16.5083 12.1 16.175C11.7667 15.8417 11.6 15.4 11.6 14.85V9.175C11.6 8.60833 11.7667 8.1625 12.1 7.8375C12.4333 7.5125 12.8833 7.35 13.45 7.35H20.175C20.7417 7.35 21.1917 7.5125 21.525 7.8375C21.8583 8.1625 22.025 8.60833 22.025 9.175V14.85C22.025 15.4 21.8583 15.8417 21.525 16.175C21.1917 16.5083 20.7417 16.675 20.175 16.675H13.45ZM4.5 21C4.11667 21 3.77083 20.85 3.4625 20.55C3.15417 20.25 3 19.9 3 19.5V4.5C3 4.11667 3.15417 3.77083 3.4625 3.4625C3.77083 3.15417 4.11667 3 4.5 3H19.5C19.9 3 20.25 3.15417 20.55 3.4625C20.85 3.77083 21 4.11667 21 4.5V5.85H13.45C12.4833 5.85 11.6833 6.16667 11.05 6.8C10.4167 7.43333 10.1 8.225 10.1 9.175V14.85C10.1 15.8 10.4167 16.5917 11.05 17.225C11.6833 17.8583 12.4833 18.175 13.45 18.175H21V19.5C21 19.9 20.85 20.25 20.55 20.55C20.25 20.85 19.9 21 19.5 21H4.5Z" fill="#000000" />
                </svg>
                <span id="walletBalance">{props?.auth?.walletDetails?.currency} {props?.auth?.walletDetails?.balance}</span>
                <i className="fa fa-refresh c-pointer"
                    style={{ marginLeft: '10px' }}
                    onClick={updateWalletBalance}></i>
                {/* onClick={setWalletBalance(dispatch, props.auth.org.org_token)} */}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps)(WalletDetails);
