import {
  formatError,
  login,
  runLogoutTimer,
  saveUserDetailsInLocalStorage,
  signUp,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const USER_DETAIL_ACTION = "user detail save";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const ORG_ACTION = "[Org Action] Organization Selected";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveUserDetailsInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.removeItem("userDetails");
  localStorage.removeItem("orgDetails");
  localStorage.removeItem("token");
  localStorage.removeItem("organizationList");
  // history.push("/auth/login");
  window.location.href = 'https://app.shiprocket.in/';
  //window.location.reload();
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        if (response.data.status === "fail") {
          const errorMessaage = formatError(response.data.message);
          dispatch(loginFailedAction(errorMessaage));
        } else {
          saveUserDetailsInLocalStorage(response.data);
          //
          /* runLogoutTimer(
                              dispatch,
                        response.data.expiresIn * 1000,
                              history,
                    );*/
          dispatch(loginConfirmedAction(response.data));
          history.push("/auth/organizations");
        }
      })
      .catch((error) => {
        //const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction("Something went wrong"));
      });
  };
}

// export function journeyRecipesData(orgToken) {
//     return (dispatch) => {
//         getJourneyApi(orgToken)
//             .then((response) => {
//                 dispatch(journeyRecipesAction(response.data));
//             })
//     };
// }

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function userDetailAction(data) {
  return {
    type: USER_DETAIL_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function OrgAction(data) {
  return {
    type: ORG_ACTION,
    payload: data,
  };
}

export function journeyRecipesAction(data) {
  return {
    type: "Journey All Recipes Data",
    payload: data,
  };
}
export function walletDetailsAction(data) {
  return {
    type: "Wallet Details",
    payload: data,
  };
}