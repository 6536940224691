import { Suspense, useEffect } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
// action
import { checkAutoLogin, checkSRLogin } from "./services/AuthService";
import { isAuthenticated, isAuthorized } from "./store/selectors/AuthSelectors";
/// Style
import { InfinitySpin } from 'react-loader-spinner';
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom.css";

import "./css/common.css";

// const SignUp = lazy(() => import("./jsx/pages/Registration"));
// const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
// const Login = lazy(() => {
//   return new Promise((resolve) => {
//     setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
//   });
// });

// const SRAuthentication = (jwtTokenEncoded) => {
//   localStorage.clear();
//   const SRUserDetail = JSON.parse(atob(jwtTokenEncoded));
//   saveUserDetailsInLocalStorage(SRUserDetail);
//   userDetailAction(SRUserDetail);
// };
// const Organizations = lazy(() => import("./jsx/pages/Organizations"));

// const LandingPage = lazy(() => import("./jsx/pages/LandingPage"));

function App(props) {
  const dispatch = useDispatch();
  var location = useLocation();
  // var isLandingPage = location.pathname.includes('/welcome');
  // console.log(isLandingPage);
  // var jwtTokenEncoded = new URLSearchParams(location.search).get('SRPayload');
  // !!jwtTokenEncoded && SRAuthentication(jwtTokenEncoded);

  useEffect(() => {
    if (location.pathname.includes('/wigzo/lite/auth/shiprocket/login/') || location.pathname.includes('/thankyou')) {
      checkSRLogin(dispatch, props);
    } else {
      checkAutoLogin(dispatch, props.history); //use same function to check org and auth
    }
  }, []);
  // useEffect(() => {
  //   isOrgSelected(dispatch, props.history);
  // }, [dispatch, props.history]);
  // let routes = (
  //   <Switch>
  //     <Route path="/wigzo/lite/auth/shiprocket/login" component={() => {
  //       window.location.href = 'https://app.shipconvert.com' + location.pathname;
  //     }} />
  //     <Route path="/auth/login" component={Login} />
  //     <Route path="/auth/page-register" component={SignUp} />
  //     <Route path="/auth/page-forgot-password" component={ForgotPassword} />
  //   </Switch>
  // );
  // let orgRoute = (
  //   <Switch>
  //     <Route path="/auth/organizations" component={Organizations}></Route>
  //   <-+
  // );
  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div style={{ height: "90vh", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }} >
                <InfinitySpin color="#4c02ff" />
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      //checkAutoLogin(dispatch, props.history);
      <div id="preloader">
        <div style={{ height: "90vh", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }} >
          <InfinitySpin color="#4c02ff" />
        </div>
      </div>
    );
  }
  // if (isLandingPage) {
  //   return (
  //     <>
  //       <Suspense
  //         fallback={
  //           <div id="preloader">
  //             <div style={{ height: "90vh", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }} >
  //               <InfinitySpin color="#4c02ff" />
  //             </div>
  //           </div>
  //         }
  //       >
  //         <Switch>
  //           <Route path="/welcome" render={(location) => <LandingPage {...location} />} />
  //         </Switch>
  //       </Suspense>
  //     </>
  //   );
  // }
  // else if (props.isAuthenticated && props.isAuthorized) {
  //   return (
  //     <>
  //       <Suspense
  //         fallback={
  //           <div id="preloader">
  //             <div style={{ height: "90vh", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }} >
  //               <InfinitySpin color="#4c02ff" />
  //             </div>
  //           </div>
  //         }
  //       >
  //         <Index />
  //       </Suspense>
  //     </>
  //   );
  // } else if (props.isAuthenticated && !props.isAuthorized) {
  //   return (
  //     <div className="vh-100">
  //       <Suspense
  //         fallback={
  //           <div id="preloader">
  //             <div style={{ display: "flex", height: "90vh", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }} >
  //               <InfinitySpin color="#4c02ff" />
  //             </div>
  //           </div>
  //         }
  //       >
  //         {orgRoute}
  //       </Suspense>
  //     </div>
  //   );
  // } else {
  //   return (
  //     <div className="vh-100">
  //       <Suspense
  //         fallback={
  //           <div id="preloader">
  //             <div style={{ display: "flex", height: "90vh", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }} >
  //               <InfinitySpin color="#4c02ff" />
  //             </div>
  //           </div>
  //         }
  //       >
  //         {routes}
  //       </Suspense>
  //     </div>
  //   );
  // }

}

const mapStateToProps = (state) => {
  return {
    ...state,
    isAuthenticated: isAuthenticated(state),
    isAuthorized: isAuthorized(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));