import React, { lazy, Fragment, useState } from "react";
import SideBar from "./SideBar";
// import NavHader from "./NavHader";
import Header from "./Header";
import { Switch, Route, useLocation } from "react-router-dom";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const OrganizationList = lazy(() => import("./OrganizationList"));
  const Journey = lazy(() => import("../../components/Dashboard/Home/Journey"));
  const Reports = lazy(() => import("../../components/Dashboard/Home/Reports"));
  const Broadcast = lazy(() =>
    import("../../components/BroadcastV2/Broadcast")
  );
  const Settings = lazy(() =>
    import("../../components/Dashboard/Home/Settings")
  );
  const CreateTemplates = lazy(() =>
    import("../../components/Dashboard/Home/CreateTemplates")
  );
  const TemplateGallery = lazy(() =>
    import("../../components/Dashboard/Home/TemplateGallery")
  );
  const WhatsappTempForm = lazy(() =>
    import("../../components/Dashboard/Home/settings/WhatsappTempForm")
  );
  // const Chart1 = lazy(() => import('../../components/Dashboard/Home/Revenue'))

  let Routes = (
    <Switch>
      <Route path='/organization-list' component={OrganizationList} />
      <Route path='/Journey' component={Journey}></Route>
      {/* <Route path='/dashboard/revenue' component={Chart1}></Route> */}

    </Switch>
  );
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  return (
    <Fragment>
      {!searchParams.has('wib') ?
        <SideBar onClick={() => onClick2()} onClick3={() => onClick3()} />
        : ``}
      {!searchParams.has('wib') ?
        <Header
          onNote={() => onClick("chatbox")}
          onNotification={() => onClick("notification")}
          onProfile={() => onClick("profile")}
          toggle={toggle}
          title={title}
          onBox={() => onClick("box")}
          onClick={() => ClickToAddEvent()}
        />
        : ''}
      <Switch>
        <Route path="/organization-list">
          <OrganizationList />{" "}
        </Route>
        <Route path="/journeys">
          <Journey />{" "}
        </Route>
        <Route path="/journey-report">
          <Reports />{" "}
        </Route>
        <Route path="/broadcast">
          <Broadcast />{" "}
        </Route>
        <Route path="/settings">
          <Settings />{" "}
        </Route>
        <Route path="/create-templates">
          <CreateTemplates />{" "}
        </Route>
        <Route path="/template-gallery">
          <TemplateGallery />{" "}
        </Route>
        <Route path="/create-whatsapp">
          <WhatsappTempForm />{" "}
        </Route>

        {/* <Route path='/dashboard/revenue' component={Chart1}></Route> */}
      </Switch>

      {/*         
         {Routes} */}
    </Fragment >
  );
};

export default JobieNav;
