import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import "../../../css/android-device.css";
import "../../../css/journey-css.css";
import "../../../css/style.css";
import uploadIcon from "../../../images/wigzolite icons/wigzolite-icons/broadcast/upload_icon.svg";
import {
  fetchAllPushTemplates, getOrgCurrency, getShortenUrl, getAllUnpagedWhatsappTemplates
} from "../../../services/GetService";
import { store } from "../../../store/store";
import GalleryModal from "../GalleryModel";
import PhonePreviewWhatsapp from './PhonePreviewWhatsapp';

const SelectMessageTemplate = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();
  const state = store.getState();
  const [broadcastTemplate, setBroadcastTemplate] = useState({
    list: [],
    count: "",
  });
  let placeholders = [
    { "item": "{{name}}", "label": "Customer Name" },
    // { "item": "{{domain_name}}", "label": "Domain Name" },
    { "item": "constantValue", "label": "Constant value" },
  ];
  const [orgCurrency, setOrgCurrency] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.formData,
    validationSchema: props.currentValidationSchema,
    onSubmit: (values) => {
      props.handleSubmission(values);
    },
  });
  // const [approvedTemplateId, setApprovedTemplateId] = useState("");      //uncomment for sms
  // const [message, setMessage] = useState(
  //   !!props.formData.message ? props.formData.message : ""
  // );
  const [showModal, setShowModal] = useState(false);
  // const handleTemplateIDchange = (e) => {      //uncomment for sms
  //   setTimeout(() => {
  //     setApprovedTemplateId(e.target.value);
  //   }, 100);
  // };
  // const handleShortenUrl = (e) => { };      //uncomment for sms
  // const getShortenURL = () => { 
  //   swal({
  //     title: "Add a short link",
  //     text: "Original URL \n Link must be prepended with https:// or https://    \n  Note: Social media URL is not trackable for analytics.",
  //     content: "input",
  //     closeOnClickOutside: true,
  //     buttons: {
  //       cancel: {
  //         text: "Cancel",
  //         visible: true,
  //         className: "btn btn-light btn-sm"
  //       },
  //       confirm: {
  //         text: "OK",
  //         value: true,
  //         visible: true,
  //         closeModal: false,
  //         className: "btn btn-primary btn-sm swal-primary-btn"
  //       },
  //     },
  //   }).then((isConfirm) => {
  //     if (!!isConfirm) {
  //       var expression =
  //         /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  //       var regex = new RegExp(expression);
  //       if (isConfirm.match(regex)) {
  //         getShortenUrl(isConfirm, state.auth.org.org_token).then((res) => {
  //           let postShortenUrlMessage = " " + res.data;
  //           setMessage(message + " " + res.data);
  //           handleShortenUrl(postShortenUrlMessage);
  //         });
  //       } else {
  //         swal("Invalid URL", "", "warning", {
  //           buttons: {
  //             confirm: {
  //               text: "OK",
  //               visible: true,
  //               className: "btn btn-outline-primary btn-sm swal-outline-primary-btn"
  //             },
  //           },
  //         }).then((isClicked) => {
  //           getShortenURL();
  //         });
  //       }
  //     }
  //   });
  // };
  // const addCouponCode = () => {
  //   swal({
  //     title: "Add Coupon Code",
  //     content: "input",
  //     closeOnClickOutside: true,
  //     buttons: {
  //       cancel: {
  //         text: "Cancel",
  //         visible: true,
  //         className: "btn btn-light btn-sm"
  //       },
  //       confirm: {
  //         text: "OK",
  //         value: true,
  //         visible: true,
  //         closeModal: false,
  //         className: "btn btn-primary btn-sm swal-primary-btn"
  //       },
  //     },
  //   }).then((isConfirm) => {
  //     if (!!isConfirm) {
  //       setTimeout(() => {
  //         setMessage(message + " " + isConfirm);
  //       }, 100);
  //     }
  //   });
  // };
  let steps = props.steps;
  let activeStep = props.activeStep;
  let isLastStep = props.steps.length - 1;
  let nextStyle = props.nextStyle;
  let disabled = props.disabled;
  const [attachments, setAttachments] = useState(
    props.formData.replacedAttachment ? props.formData.replacedAttachment : ""
  );
  const [buttons, setButtons] = useState(
    JSON.parse(props.formData.replacedButtonUrl).length > 0 ? JSON.parse(props.formData.replacedButtonUrl) : []
  );
  const [selectedTemplateFooter, setSelectedTemplateFooter] = useState('');
  const [persistedVariables, setPersistedVariables] = useState(
    _.isEmpty(props.formData.variables)
      ? "[]"
      : Object.values(JSON.parse(props.formData.variables))
  );
  const [dropdownVars, setDropdownVars] = useState(_.isEmpty(props.formData.dropdownVars) ? {} : props.formData.dropdownVars);
  let replacedVars = {};
  let replacedButtons = {};
  let changedMsg = formik.values.message;
  const resetFields = () => {
    return new Promise((resolve) => {
      props.setFormData({
        ...props.formData,
        mediaUrl: "",
        variables: "[]",
        dropdownVars: {},
        replacedVariables: "{}",
        // attachments: "",
        replacedAttachment: "",
        replacedButtonUrl: "[]",
      });

      resolve('message form props data reset');
    });
  };
  const handleTemplateChange = async (e) => {
    await resetFields().then((response) => {
      console.log(response);
    });
    // formik.resetForm();
    //You'll get selected template object. Pick required fields from the object and use them accordingly.
    let templateUuid = e?.target?.value || props.formData.selectedTemp;
    let selectedTemplate = e.status === 'selectedTemplateData' ? e.selectedTemp : _.find(broadcastTemplate?.list, {
      uuid: templateUuid,
    });
    let selectedTemplateVariables = JSON.parse(selectedTemplate.variables);
    let selectedTemplateButtons = JSON.parse(selectedTemplate.buttons);
    formik.setFieldValue("selectedTemp", selectedTemplate?.uuid); //set Template Id
    formik.setFieldValue("selectedTempName", selectedTemplate?.templateName);
    setTimeout(() => formik.setFieldTouched("selectedTempName", true));
    formik.setFieldValue("message", selectedTemplate?.message);
    formik.setFieldValue("variables", selectedTemplate?.variables);
    formik.setFieldValue("replacedAttachment", selectedTemplate?.attachments);
    formik.setFieldValue("broadcastTemplateUuid", selectedTemplate?.uuid);
    formik.setFieldValue("updatedAt", selectedTemplate?.updatedAt);
    formik.setFieldValue("templateType", selectedTemplate?.templateType);
    formik.setFieldValue("provider", selectedTemplate?.provider);
    formik.setFieldValue("status", selectedTemplate?.status);
    formik.setFieldValue("language", selectedTemplate?.language);
    formik.setFieldValue("mediaUrl", selectedTemplate?.attachments);
    setSelectedTemplateFooter(selectedTemplate?.footer);
    setPersistedVariables(
      !!selectedTemplate?.variables
        ? selectedTemplateVariables
        : persistedVariables
    );
    setButtons(
      !!selectedTemplate ? selectedTemplateButtons : buttons
    );
    setAttachments(selectedTemplate?.attachments);
    // let vars = selectedTemplate?.message.match(/{{([^{}]*)}}/g);      //uncomment for sms
    // changedMsg = selectedTemplate?.message;
    // vars &&
    //   vars.forEach((e, i) => {
    //     changedMsg = changedMsg.replace(
    //       e,
    //       selectedTemplateVariables[i]
    //     );
    //     formik.setFieldValue("message", changedMsg);
    //   });
    let dropdownVarsValue = {};
    !!selectedTemplate?.variables &&
      !!selectedTemplateVariables &&
      selectedTemplateVariables.forEach((e, i) => {
        replacedVars[e] = e;
        dropdownVarsValue[i] = '';
      });
    setDropdownVars({ ...dropdownVarsValue });
    formik.setFieldValue("dropdownVars", { ...dropdownVarsValue });
    formik.setFieldValue(
      "replacedVariables",
      JSON.stringify({ ...replacedVars })
    );
    replacedButtons = selectedTemplate?.buttons;
    formik.setFieldValue("replacedButtonUrl", replacedButtons);

  };
  let dropdownVarsValueChange = (e, i) => {
    let dropdownVarsValue = dropdownVars;
    let targetedValue = e.target.value.trim();
    dropdownVarsValue[i.props.dataindex] = targetedValue;
    document.getElementById(`customVarTextField${i.props.dataindex}`).value = targetedValue === 'constantValue' ? '' : targetedValue;
    setDropdownVars(dropdownVarsValue);
    formik.setFieldValue("dropdownVars", dropdownVarsValue);
    if (e.target.value.indexOf('{{') >= 0) {
      customVar(e);
    }
  };
  //replacing variables
  let customVar = (e) => {
    let targetedValue = e.target.value.trim();
    let index = parseInt(e.nativeEvent.target.getAttribute('dataindex'));
    replacedVars = JSON.parse(formik.values.replacedVariables);
    // replacedVars = !_.isEmpty(JSON.parse(props.formData.replacedVariables)) ? JSON.parse(props.formData.replacedVariables) : JSON.parse(formik.values.replacedVariables);
    replacedVars[`{{$${index + 1}}}`] = targetedValue;
    formik.setFieldValue("replacedVariables", JSON.stringify(replacedVars));
    // document.getElementById(`customVarTextField${index}`).value = targetedValue;
  };
  let handleButtonUrl = (e) => {
    replacedButtons = buttons;
    // formik.values.replacedButtonUrl = buttons;
    let index = parseInt(e.target.dataset.index);
    let btnUrl = e.target.value.trim();
    replacedButtons[index].url = btnUrl;
    formik.setFieldValue("replacedButtonUrl", JSON.stringify(replacedButtons));
    document.getElementById(`btn${index}`).value = btnUrl;
  };
  let handleAttachments = (e) => {
    let replacedAttachment = !!e.target ? e.target.value.trim() : e.trim();
    // replacedAttachment = !!replacedAttachment && replacedAttachment.search('http') >= 0 ? new URL(replacedAttachment) : replacedAttachment;
    // replacedAttachment = !!replacedAttachment && !!replacedAttachment.href ? replacedAttachment.href.replace(replacedAttachment.search, '') : replacedAttachment;
    formik.setFieldValue("replacedAttachment", replacedAttachment);
    // formik.setFieldValue("mediaUrl", replacedAttachment);
    // document.getElementById('replacedAttachment').value = replacedAttachment;
  };
  // const handleMessageChange = (e) => {       //uncomment for sms
  //   props.formField.message = e.target.value;
  // };
  // const handleApprovedTemplateIdChage = (e) => {
  //   props.getApprovedTemplateId(e.target.value);
  // };
  // const handlecampaignNameChange = (e) => {
  //   props.formField.campaignName = e.target.value;

  // };
  const handleGallerySelection = (obj) => {
    handleAttachments(obj.mediaFileUrl);
  };
  function hideGalleryModal(value) {
    !value && setShowModal(false);
  }
  function fetchAllTemplates() {
    getAllUnpagedWhatsappTemplates(state.auth.org.org_token, 'broadcast').then(
      (response) => {
        setBroadcastTemplate({
          list: response.data.templates.sort((obj, i) => i.updatedAt.localeCompare(obj.updatedAt)),
          count: response?.data?.count,
        });
      }
    );
    if (props.selectedChannel === "push") {
      fetchAllPushTemplates(state.auth.org.org_token).then((response) => {
        setBroadcastTemplate(response?.data);
      });
    }
  }
  function replacedVariableValue(i) {
    let replacedVariableValue = ((!_.isEmpty(JSON.parse(props.formData.replacedVariables)) && JSON.parse(props.formData?.replacedVariables)[`{{$${i + 1}}}`]) || (!!formik.values.replacedVariables && JSON.parse(formik.values?.replacedVariables)[`{{$${i + 1}}}`]));
    return replacedVariableValue;
  }
  // function replacedAttachmentValue() {
  //   let replacedAttachmentValue = (!!formik.values.replacedAttachment ? formik.values.replacedAttachment : props.formData.replacedAttachment);
  //   return replacedAttachmentValue;
  // }
  function replacedButtonUrlValue(i) {
    let replacedButtonUrlValue = ((JSON.parse(props.formData.replacedButtonUrl).length > 0 && JSON.parse(props.formData?.replacedButtonUrl)[i]?.url) || (!!formik.values.replacedButtonUrl && JSON.parse(formik.values?.replacedButtonUrl)[i]?.url));
    return replacedButtonUrlValue;
  }
  function dropdownVarsValue(i) {
    let dropdownVarsValue = ((formik.values.hasOwnProperty('dropdownVars') && !!formik.values?.dropdownVars[i]) ? formik.values.dropdownVars[i] : props.formData?.dropdownVars[i]);
    return dropdownVarsValue === undefined ? '' : dropdownVarsValue;
  }
  useEffect(() => {
    fetchAllTemplates();
    getOrgCurrency(state.auth.org.org_token).then((res) => {
      setOrgCurrency(res.data.orgCurrency);
    });
    props.handleButtonState(formik.isValid);
  }, []);
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (
      (!!props.formData.campaignName || !!formik.values.campaignName) &&
      (!!props.formData.selectedTemp || !!formik.values.selectedTemp)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formik.values, formik.isValid, formik.dirty]);

  return (
    <>
      <section>
        <div className="broadcast-channels-container d-flex">
          <button
            style={nextStyle}
            form={steps[activeStep].label.split(" ").join("")}
          >
            {" "}
            {activeStep === isLastStep ? "Send" : "Next"}
          </button>

          <div
            style={{ paddingTop: "0rem" }}
            className="message-left-container custom-selectable-dropdown">
            <form id={"Message"} onSubmit={formik.handleSubmit}>
              <div className="custom-card-container">
                <h3 className="custom-card-heading">Message</h3>
                <div className="custom-card-body">
                  <div
                    className="validate-redux-form broadcast-message-container"
                    style={{ textAlign: "left" }}
                  >
                    <div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="waForm-subheading">Campaign Name</h4>
                        <div style={{ width: "63%" }}>
                          <input
                            type="hidden"
                            name="replacedVariables"
                            value={formik.values.replacedVariables}
                            required
                          />
                          <input
                            name="message"
                            type="hidden"
                            value={formik.values.message}
                            required
                          />
                          <input
                            type="hidden"
                            name="selectedTempName"
                            value={formik.values.selectedTempName}
                            required
                          />
                          <TextField
                            variant="outlined"
                            name="campaignName"
                            autoComplete="off"
                            className="form-control "
                            label=""
                            placeholder="Enter Campaign Name"
                            id="campaignname"
                            inputProps={{ maxLength: 50 }}
                            onChange={formik.handleChange}
                            style={{ marginTop: "14px" }}
                            value={formik.values.campaignName}
                            error={formik.touched.campaignName && Boolean(formik.errors.campaignName)}
                          />
                        </div>
                        <p
                          id="ErrorMessage"
                          style={{
                            display: "none",
                            color: "red",
                            fontSize: "10px",
                            marginTop: "15px",
                          }}
                        >
                          Campaign name should not contain special
                          characters except " - " and " _ "
                        </p>
                      </div>
                      <div
                        className=""
                        style={{
                          display:
                            props.formData.selectedChannel != "sms"
                              ? "flex"
                              : "none",
                          width:
                            props.formData.selectedChannel !== "sms"
                              ? "100%"
                              : "",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="waForm-subheading">
                          Select Template
                        </h4>
                        <div className="d-flex flex-column align-items-end" style={{ width: '63%' }}>

                          <FormControl style={{ width: '100%' }}>

                            <Select
                              displayEmpty
                              variant="outlined"
                              style={{ marginTop: "14px" }}
                              name="selectedTemp"
                              value={
                                formik.values.selectedTemp
                                  ? formik.values.selectedTemp
                                  : props.formData.selectedTemp
                              }
                              renderValue={formik.values.selectedTemp !== "" || props.formData.selectedTemp !== "" ? undefined : () => "Select Template"}
                              onChange={(e) => handleTemplateChange(e)}
                              error={formik.touched.selectedTemp && Boolean(formik.errors.selectedTemp)}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                            >
                              {broadcastTemplate.list.length <= 0 ? (
                                <p style={{ margin: "0 25px" }}>
                                  {" "}
                                  'My List' is empty, please add templates from 'Template Gallery'.{" "}
                                </p>
                              ) : (
                                !!broadcastTemplate.list &&
                                broadcastTemplate.list.map((templates, i) => {
                                  return (
                                    <MenuItem
                                      key={templates?.uuid}
                                      value={templates?.uuid}
                                    >
                                      {templates?.templateName}
                                    </MenuItem>
                                  );
                                })
                              )}
                            </Select>
                          </FormControl>
                          <div className="d-flex align-items-baseline">
                            <a className="wigzo-hyperlink" onClick={() => !!searchParams.has('wib') ? history.push(`/create-whatsapp?wib=1`) : history.push('/create-whatsapp')}>
                              Create new Template</a> &#160;
                            or
                            &#160; <a className="wigzo-hyperlink" onClick={() => !!searchParams.has('wib') ? history.push(`/template-gallery?wib=1`) : history.push('/template-gallery')}>
                              Template Gallery</a>
                          </div>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          display:
                            props.formData.selectedChannel === "sms" &&
                              (orgCurrency === "₹" || orgCurrency === "INR")
                              ? "block"
                              : "none",
                        }}
                      >
                        <h4 className="waForm-subheading">
                          Approved Template ID
                        </h4>
                        <TextField
                          variant="outlined"
                          name="templateID"
                          className="form-control "
                          defaultValue={approvedTemplateId}
                          label="Approved Template ID"
                          id="templateID"
                          onKeyUp={handleTemplateIDchange}
                        />
                      </div> */}
                    </div>
                    {/* <div
                      style={{
                        width: "100%",
                        display:
                          props.formData.selectedChannel === "sms"
                            ? "block"
                            : "none",
                      }}
                    >
                      <h4 className="waForm-subheading">Message Text</h4>
                      <textarea
                        onChange={handleMessageChange}
                        value={message}
                        label={"Message"} //optional
                        className="form-control"
                        rows={3}
                      />
                    </div>
                    <div className="message-alteration-options">
                      <span
                        onClick={addCouponCode}
                        style={{
                          display:
                            props.formData.selectedChannel != "sms" &&
                            "none",
                        }}
                      >
                        Add Coupon Code
                      </span>
                      <span
                        onClick={getShortenURL}
                        style={{
                          display:
                            props.formData.selectedChannel != "sms" &&
                            "none",
                        }}
                      >
                        Shorten URL
                      </span>
                      <span
                        onClick={fetchAllTemplates}
                        style={{
                          display:
                            props.formData.selectedChannel === "sms"
                              ? "block"
                              : "none",
                          paddingRight: "20px",
                        }}
                      >
                        <i className="fa fa-refresh"></i> Refresh
                      </span>
                    </div> */}
                    <div
                      style={{
                        display:
                          props.formData.selectedChannel != "sms"
                            ? "block"
                            : "none",
                      }}
                    >
                      {!!attachments ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                          }}
                        >
                          <h6
                            className="waForm-subheading"
                            style={{ marginRight: "25px" }}
                          >
                            Image URL
                          </h6>
                          <div style={{ width: '63%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <TextField
                              variant="outlined"
                              autoComplete="off"
                              className="form-control"
                              label=""
                              id="replacedAttachment"
                              name="replacedAttachment"
                              // defaultValue={replacedAttachmentValue().search('}}') >= 0 ? '' : replacedAttachmentValue()}
                              value={formik.values.replacedAttachment.search('}}') >= 0 ? '' : formik.values.replacedAttachment}
                              // key={replacedAttachmentValue()}
                              placeholder="Enter URL"
                              style={{
                                width: "100%",
                              }}
                              onChange={formik.handleChange}
                              onChangeCapture={formik.handleBlur('replacedAttachment')}
                              error={!!formik.touched.replacedAttachment && Boolean(formik.errors.replacedAttachment)}
                              required
                            />
                            <div className="d-flex justify-content-between align-items-baseline" style={{ width: '100%' }}>
                              <span style={{ fontSize: '12px', color: (!!formik.touched.replacedAttachment && !!formik.errors.replacedAttachment) ? 'red' : '#7e7e7e' }}>Acceptable image formats: PNG, JPG, JPEG</span>
                              <a className="wigzo-hyperlink" onClick={() => setShowModal(true)}>
                                <img src={uploadIcon} height="18"></img>
                                Upload Image
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {buttons.length > 0 &&
                        buttons.map((e, i) => {
                          return e.hasOwnProperty('url') && (
                            <div
                              key={i}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                              }}
                            >
                              <h6
                                className="waForm-subheading"
                                style={{

                                  marginRight: "25px",
                                }}
                              >
                                {" "}
                                Button URL
                              </h6>
                              <div style={{ width: "63%" }}>
                                <TextField
                                  variant="outlined"
                                  id={`btn${i}`}
                                  inputProps={{ 'data-index': i }}
                                  autoComplete="off"
                                  autoFocus
                                  className="form-control "
                                  defaultValue={replacedButtonUrlValue(i) && replacedButtonUrlValue(i).search('}}') >= 0 ? '' : replacedButtonUrlValue(i)}
                                  key={replacedButtonUrlValue(i)}
                                  label=""
                                  placeholder="Enter URL"
                                  style={{
                                  }}
                                  onChange={handleButtonUrl}
                                  onChangeCapture={_.debounce(formik.handleBlur('replacedButtonUrl'), 100)}
                                  error={!!formik.touched.replacedButtonUrl && Boolean(formik.errors.replacedButtonUrl) && Boolean(formik.errors.replacedButtonUrl[i].url)}
                                  required
                                />
                              </div>
                            </div>
                          );
                        })}
                      {
                        !!persistedVariables &&
                        persistedVariables.map((e, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                              }}
                            >
                              <h6
                                className="waForm-subheading"
                                style={{

                                  marginRight: "25px",
                                }}
                              >
                                Variable {i + 1}
                              </h6>
                              <div className="d-flex align-items-center " style={{ width: '63%' }}>
                                <div style={{ position: 'absolute', left: '19.5%' }}>{e}</div>
                                <FormControl style={{ width: '100%' }}>
                                  <Select
                                    displayEmpty
                                    variant="outlined"
                                    className=""
                                    renderValue={!!dropdownVarsValue(i) ? undefined : () => "Select value"}
                                    value={dropdownVarsValue(i)}
                                    placeholder="Select Variable"
                                    onChange={(e, i) => dropdownVarsValueChange(e, i)}
                                    required
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                  >
                                    {
                                      placeholders.map((key) => {
                                        return (
                                          <MenuItem key={key.item} value={key.item} dataindex={i} dropdownvar={key.item}>{key.label}</MenuItem>
                                        );
                                      })
                                    }
                                  </Select>
                                </FormControl>
                                <TextField
                                  variant="outlined"
                                  type={'text'}
                                  autoFocus
                                  autoComplete="off"
                                  id={`customVarTextField${i}`}
                                  className="form-control "
                                  label=""
                                  style={{ width: "100%", marginLeft: "10px" }}
                                  disabled={dropdownVarsValue(i).length > 0 ? !(dropdownVarsValue(i).indexOf('{{') < 0) : true}
                                  defaultValue={replacedVariableValue(i) !== undefined && replacedVariableValue(i).indexOf('{{$') < 0 ? replacedVariableValue(i) : ''}
                                  key={replacedVariableValue(i)}
                                  inputProps={{ 'dataindex': i, maxLength: 50 }}
                                  placeholder="Constant value"
                                  onChange={customVar}
                                  required={dropdownVarsValue(i).length > 0 && dropdownVarsValue(i).search('}}') < 0}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="message-right-container col-md-6" style={{ marginTop: '50px' }} >

            <PhonePreviewWhatsapp message={formik.values.message} attachment={formik.values?.replacedAttachment?.search('http') >= 0 ? formik.values?.replacedAttachment : !formik.values?.replacedAttachment ? '' : 'https://media.shipconvert.com/SRConvert/imageplaceholder.jpeg'} replacedButtonUrl={formik.values.replacedButtonUrl} org_name={state.auth.org.org_name} footer={selectedTemplateFooter} />
          </div>
        </div>

        <GalleryModal handleSelection={(e) => setTimeout(handleGallerySelection(e), 0)} handleGalleryClose={hideGalleryModal} showModal={showModal}></GalleryModal>
      </section>
    </>
  );
};

export default SelectMessageTemplate;
