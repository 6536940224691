import React from 'react'
import { Link } from 'react-router-dom'

export default function ThankYouPage() {
  return (
    <div className="content-body">
        <div className="thank_you_container">
            
            <div className="thank_you_page_container" >
                <div className='sub-container' >

                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="55.5" cy="55.5" r="53" fill="white" stroke="#0F9F09" stroke-width="5"/>
                    <path d="M48.4564 72.6971L32.0493 56.3049L35.1989 53.1582L48.4564 66.4037L76.5828 38.3027L79.7324 41.4495L48.4564 72.6971Z" fill="#0F9F09"/>
                </svg>
                <div>
                    <h5 className="success-message" >You have successfully set up your Account</h5>
                    <h5 className="info-message" >Using Engage Facebook Business Manager ID !</h5>
                </div>
                <div>
                    <h5 className="success-message" >We are analysing your orders for best customer experience</h5>
                </div>
                <div>
                    <Link to='/dashboard' className="goToHomePage" >
                        Go to Homepage
                    </Link>
                </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}
