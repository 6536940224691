import * as Yup from 'yup';
import moment from 'moment';
import broadcastFormData from './FormData';
const {
  selectedChannel,
  campaignName,
  selectedTemp,
  selectedTempName,
  status,
  message,
  variables,
  replacedVariables,
  targetedAudience,
  replacedButtonUrl,
  replacedAttachment,
  broadcastTemplateUuid,

} = broadcastFormData;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

const validateSchema = [
  Yup.object().shape({
    selectedChannel: Yup.string().required(`Please Select Channel`),
  }),
  Yup.object().shape({
    targetedAudience: Yup.object().shape({
      segmentType: Yup.string().required(`targeted audience`),
      selectedSegment: Yup.string().required(`targeted audience`)
    })

  }),
  Yup.object().shape({
    campaignName: Yup.string().required(`campaign name is required`).trim(),
    selectedTemp: Yup.string().required(`selected template is required`),
    variables: Yup.array().of(Yup.string().required(`campaign name is required`)),
    replacedAttachment: Yup.string().trim().matches(
      /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg))/
    ),
    replacedButtonUrl: Yup.array()
      .of(
        Yup.object().shape({
          url: Yup.string().trim().matches(
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
          ),
        })
      )
  }),
  Yup.object().shape({
    status: Yup.string(),
    scheduleAt: Yup.number().when(['status'], {
      is: (status) => status !== 'DRAFT',
      then: Yup.number().required('Field is required'),
      otherwise: Yup.number(),
    }),
    repeatAfterDays: Yup.number().when(['status'], {
      is: (status) => status === 'RECURRING',
      then: Yup.number().required('Field is required'),
      otherwise: Yup.number(),
    }),
    repeatStopDateTime: Yup.string().when(['status'], {
      is: (status) => status === 'RECURRING',
      then: Yup.string().required('Field is required'),
      otherwise: Yup.string(),
    }),
  })
];
export default validateSchema;