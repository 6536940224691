import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import {
  Dropdown,
  Card,
  Col,
  // Pagination,
  ListGroup,
} from "react-bootstrap";
import { store } from "../../../../store/store";
import { getRulesList } from "../../../../services/AuthService";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { deleteRecipe } from "../../../../services/GetService";
import ActiveCampaignsImg from "../../../../images/wigzolite icons/wigzolite-icons/dashboard/tpc.svg";
import searchIcon from "../../../../images/wigzolite icons/wigzolite-icons/common/search_icon.svg";
import { toggleRuleStatus } from "../../../../services/AuthService";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import _debounce from "lodash/debounce";
import Pagination from "../Home/Paignation";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { InfinitySpin } from "react-loader-spinner";
import { tr } from "date-fns/locale";
const JourneyOverview = () => {
  // let campaignData = campaigns
  const state = store.getState();
  const [activeCampaigns, setActiveCampaigns] = useState({ list: [], count: "", loading: true });
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("all");
  const [searchKeyword, setSearchKeyword] = useState("");
  // const [totalPages, setTotalPages] = useState(0);
  const [ruleStatusChange, setRuleStatusChange] = useState(true);
  const [perPage, setPerPage] = useState(20);
  const ruleStatus = [
    { key: 'all', label: 'All' },
    { key: 'enabled', label: 'Enabled' },
    { key: 'disabled', label: 'Disabled' }
  ];
  const goToCreateForm = () => {
    history.push("/journeys/create-journey");
  };
  const changeJourneyStatus = (e) => {
    setStatus(e.target.value);
  };
  const getCTR = (sentCount, clickedCount) => {
    return (
      !!sentCount &&
      !!clickedCount &&
      !!Number(sentCount) &&
      !!Number(clickedCount) &&
      ((Number(clickedCount) / Number(sentCount)) * 100).toFixed(2)
    );
  };
  const setActiveCampaignsFn = () => {
    setActiveCampaigns({ loading: true });
    getRulesList(state.auth.org.org_token, page, perPage, searchKeyword, status === "all" ? '' : status).then((response) => {
      setActiveCampaigns({ list: response.data.items, count: response.data.count, loading: false });
    });
  };
  const changeToggleStatus = (e) => {
    toggleRuleStatus(e.target.dataset.uuid, state.auth.org.org_token).then((res) => {
      setRuleStatusChange(!ruleStatusChange);
      swal("Status Changed Successfully", "", "success", {
        buttons: {
          confirm: {
            text: "OK",
            visible: true,
            className: "btn btn-outline-primary btn-sm swal-outline-primary-btn"
          },
        }
      });
    }).catch((error) => {
      console.error(error);
      swal({
        title: "Something went wrong!",
        text: "Please try again",
        icon: "error",
        closeOnClickOutside: false,
        buttons: {
          confirm: {
            text: "OK",
            visible: true,
            className: "btn btn-outline-primary btn-sm swal-outline-primary-btn"
          },
        },
      });
    });
    // setTimeout(() => {
    //   setRuleStatusChange(!ruleStatusChange);
    // }, 500);
    // swal("Status Changed Successfully", "", "success", { buttons: {
    //     confirm: {
    //       text: "OK",
    //         visible: true,
    //           className: "btn btn-outline-primary btn-sm swal-outline-primary-btn";
    //     },
    //   };
    // });
    //     // history.go(0);
  };

  let onPageChange = (e) => {
    e.preventDefault();
    setPage(e.target.innerText);
    //   getRulesList(state.auth.org.org_token, parseInt(e.target.innerText), perPage, searchKeyword, status === "all" ? '' : status).then((response) => {
    //   setPage(e.target.innerText);
    //   setActiveCampaigns({ list: response.data.items, count: response.data.count, loading: false });
    // });
  };

  const deleteRecipeFn = (e) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this journey!",
      icon: "warning",
      closeOnClickOutside: true,
      buttons: {
        cancel: true,
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          closeModal: false,
        },
      },
    }).then((isConfirm) => {
      if (!!isConfirm) {
        deleteRecipe(e, state.auth.org.org_token);
        swal("Archived Successfully", "", "success", {
          buttons: {
            confirm: {
              text: "OK",
              visible: true,
              className: "btn btn-outline-primary btn-sm swal-outline-primary-btn"
            },
          },
        }).then((isClicked) => {
          setActiveCampaignsFn();
        });
      }
    });
  };

  useEffect(() => {
    // getRulesList(state.auth.org.org_token, page, perPage, searchKeyword, status === "all" ? '' : status).then((response) => {
    //   setActiveCampaigns({ list: response.data.items, count: response.data.count, loading: false });
    // });
    setActiveCampaignsFn();
  }, [ruleStatusChange, searchKeyword, status, page]);


  const debounceSearch = _debounce(
    (value) => {
      setSearchKeyword(value);
      if (value.length > 0) {
        document.getElementById('resetSearch').style.display = 'block';
      }
    },
    700,
    []
  );
  let resetSearch = () => {
    setSearchKeyword('');
    document.getElementById('searchCampaigns').value = '';
    document.getElementById('resetSearch').style.display = 'none';

  };
  const [modalCentered, setModalCentered] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  let closePreview = () => {
    setModalCentered(false);
  };
  {
    return (
      <>
        <div style={{ paddingRight: "4.5rem", paddingLeft: '2rem' }} className="row content-body ">
          <div className="broadcast-overview-heading d-flex justify-content-between">
            <div>
              <h2>Journey</h2>
              <p>
                Easily send journey to reach customers at the right place
              </p>
            </div>
            <div>
              <button
                className="mr-2 btn btn-primary btn-sm"
                onClick={goToCreateForm}
              >
                Create Journey +
              </button>
            </div>
          </div>

          <div className="broadcast-overview-filters custom-selectable-secondary-dropdown">
            <div>
              <div className="broadcast-filters-container">
                <label htmlFor="statusLabel">Status</label>
                <FormControl style={{ minWidth: "130px", borderRadius: "5px" }}>
                  {/* <InputLabel style={{ marginLeft: "9%" }} id="statusLabel">
                    Status
                  </InputLabel> */}
                  <Select
                    style={{ border: "1px solid #4c02ff" }}
                    variant="outlined"
                    labelId="statusLabel"
                    id="statusLabel"
                    value={status}
                    onChange={changeJourneyStatus}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    {ruleStatus.map((list, i) => {
                      return (
                        <MenuItem value={list.key} selected={i == 0}>
                          {list.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="broadcast-filters-container">
                <Fragment>
                  <img className="search-icon" src={searchIcon} style={{ position: 'absolute', left: '40.7%' }} />
                  <input
                    style={{
                      display: "block",
                      height: "45px",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      margin: "0 auto",
                      left: "40%",
                      position: "absolute",
                      width: "25%"
                    }}
                    type="search"
                    id="searchCampaigns"
                    className="searchOrg form-control"
                    onChange={(e) => debounceSearch(e.target.value.toLowerCase())}
                    autoComplete="false"
                    placeholder="Search by name"
                  />
                </Fragment>
                <p
                  id="resetSearch"
                  onClick={() => resetSearch()}
                  style={{
                    display: "none",
                    cursor: "pointer",
                    color: "grey",
                    position: "absolute",
                    marginBottom: "0%",
                    right: "36%",
                    fontSize: "larger",
                  }}>x</p>
              </div>
            </div>
          </div>
          <div className="p-0 my-2 mt-4 d-flex align-items-baseline  justify-content-between col-xl-12 col-xxl-12">
            <h3 className="text-black  " style={{
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px"
            }}>
              Reports
              <img style={{ marginLeft: "10px" }} src={ActiveCampaignsImg} />
            </h3>
          </div>
          {
            activeCampaigns.loading ?
              <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                <InfinitySpin color="#4c02ff" />
                <span>Hold on, We are fetching data for you.</span>
              </div> :
              activeCampaigns.list.length <= 0 ?
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", justifyContent: "center", background: "white", borderRadius: "15px", padding: "15px" }} >

                  <svg width="120" height="150" viewBox="0 0 89 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M42.9705 20.3848L27.2607 61.1727L73.7172 61.5364L58.3438 20.3848H42.9705Z" fill="#989898" />
                    <path d="M50.4689 22.5235C51.7563 22.5235 52.8001 21.4811 52.8001 20.1953C52.8001 18.9096 51.7563 17.8672 50.4689 17.8672C49.1814 17.8672 48.1377 18.9096 48.1377 20.1953C48.1377 21.4811 49.1814 22.5235 50.4689 22.5235Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.1191 15.7929C52.0706 15.3714 51.9396 13.8574 51.9396 13.8574H49.2934C49.2934 13.8574 49.1673 15.3676 48.1139 15.7929C47.6023 15.9981 46.4556 16.2435 45.3669 16.6927C44.6468 16.9899 44.0311 17.4936 43.5981 18.1405C43.165 18.7872 42.9336 19.5478 42.9336 20.3259V20.3398H58.2994V20.3259C58.2992 19.5478 58.068 18.7872 57.6349 18.1405C57.2017 17.4936 56.5861 16.9899 55.8661 16.6927C54.7774 16.2435 53.6307 15.9993 53.1191 15.7929Z" fill="#868A91" />
                    <path d="M51.6579 13.498H49.5762V13.858H51.6579V13.498Z" fill="#61656D" />
                    <path d="M51.3279 12.7715H49.9053V13.4976H51.3279V12.7715Z" fill="#61656D" />
                    <path d="M51.6579 13.498H49.5762V13.858H51.6579V13.498Z" fill="#61656D" />
                    <path d="M51.3279 12.7715H49.9053V13.4976H51.3279V12.7715Z" fill="#61656D" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M50.7432 12.7709H50.4912V0L50.7432 0.0188762V12.7709Z" fill="#61656D" />
                    <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M58.3002 20.3259C58.3 19.5478 58.0688 18.7872 57.6357 18.1405C57.2025 17.4936 56.5869 16.9899 55.8669 16.6927C54.7782 16.2435 53.6315 15.9993 53.1199 15.7929C52.0714 15.3714 51.9404 13.8574 51.9404 13.8574H51.3759L51.328 13.8662C51.328 13.8662 51.1617 15.7275 52.7998 16.504C53.5155 16.8425 54.9155 17.4679 55.4145 18.0821C55.8707 18.6446 55.9261 19.3846 55.9261 20.3398H58.3065L58.3002 20.3259Z" fill="black" />
                    <path d="M7.46686 112H73.5999L82.6389 63.1268C82.678 62.9109 82.6704 62.6886 82.6149 62.4762C82.5595 62.2636 82.4574 62.0659 82.3163 61.897C82.1764 61.7283 82 61.5924 81.8009 61.4991C81.6018 61.4059 81.3838 61.3574 81.1646 61.3574H15.0313L5.99254 110.239C5.9536 110.454 5.96255 110.675 6.01875 110.887C6.07482 111.099 6.17677 111.296 6.3174 111.463C6.45802 111.632 6.63381 111.766 6.8324 111.859C7.031 111.953 7.2476 112 7.46686 112Z" fill="#A5A5A5" />
                    <path d="M73.5995 111.999H6.99648C6.62954 111.999 6.27519 111.866 6.00087 111.622C5.72654 111.379 5.55113 111.043 5.50829 110.679L0.010438 64.5663C-0.0145121 64.3564 0.00539532 64.1435 0.0687788 63.9418C0.132162 63.74 0.237632 63.5539 0.378261 63.3958C0.518889 63.2377 0.691401 63.111 0.88445 63.0243C1.0775 62.9376 1.2868 62.8927 1.49863 62.8926H66.4144C66.7812 62.8925 67.1354 63.0269 67.4096 63.2701C67.6839 63.5135 67.8593 63.8489 67.9026 64.2127L73.5995 111.999Z" fill="#C4C4C4" />
                    <path d="M14.9795 61.6391L16.0191 56.7538C16.0927 56.4076 16.283 56.0971 16.5582 55.8742C16.8334 55.6514 17.1769 55.5295 17.5312 55.5293H27.6247C27.9678 55.5294 28.3012 55.6433 28.5727 55.853C28.844 56.0628 29.038 56.3566 29.1242 56.6882L30.3982 61.6089L14.9795 61.6391Z" fill="#A5A5A5" />
                    <g opacity="0.4">
                      <path opacity="0.4" d="M44.331 82.2854L41.4794 79.4375L35.7736 85.1358L30.0704 79.4375L27.22 82.2854L32.9233 87.9825L27.2188 93.6782L30.0704 96.5261L35.7749 90.8304L41.4794 96.5287L44.331 93.6845L38.6253 87.9837L44.331 82.2854Z" fill="black" />
                    </g>
                    <path d="M88.2528 108.679C88.2629 108.679 88.273 108.677 88.2818 108.673C88.2906 108.668 88.2982 108.662 88.3045 108.654C88.3171 108.638 88.3247 108.616 88.3247 108.595C88.3247 108.575 88.3171 108.553 88.3045 108.537L87.7601 107.954C87.7425 107.942 87.7223 107.936 87.7021 107.936C87.6807 107.936 87.6606 107.942 87.6442 107.954C87.6316 107.971 87.624 107.992 87.624 108.012C87.624 108.034 87.6316 108.055 87.6442 108.071L88.1885 108.654C88.2062 108.669 88.2289 108.679 88.2528 108.679Z" fill="white" />
                    <path d="M87.8312 109.016C87.8413 109.016 87.8514 109.014 87.8602 109.009C87.869 109.005 87.8766 108.998 87.8829 108.99C87.8955 108.974 87.9018 108.954 87.9018 108.932C87.9018 108.911 87.8955 108.891 87.8829 108.874L87.3385 108.292C87.3221 108.279 87.3007 108.271 87.2806 108.271C87.2591 108.271 87.2377 108.279 87.2213 108.292C87.2087 108.308 87.2012 108.328 87.2012 108.35C87.2012 108.371 87.2087 108.391 87.2213 108.407L87.7657 108.99C87.7833 109.006 87.8073 109.016 87.8312 109.016Z" fill="white" />
                    <path d="M87.3962 109.341C87.4063 109.341 87.4164 109.338 87.4252 109.334C87.434 109.329 87.4416 109.323 87.4479 109.314C87.4605 109.298 87.4681 109.278 87.4681 109.256C87.4681 109.235 87.4605 109.215 87.4479 109.198L86.9035 108.616C86.8884 108.601 86.867 108.592 86.8456 108.592C86.8241 108.592 86.8027 108.601 86.7876 108.616C86.7725 108.631 86.7637 108.652 86.7637 108.674C86.7637 108.695 86.7725 108.716 86.7876 108.731L87.332 109.314C87.3446 109.341 87.3572 109.341 87.3962 109.341Z" fill="white" />
                    <path d="M55.9774 32.7496C55.3364 31.6582 54.2953 30.8552 53.0799 30.5064C50.2491 29.733 47.1054 30.1069 45.5721 31.7817C43.7413 33.1235 43.4336 36.4884 45.967 36.4884C47.5362 36.4884 47.9311 33.3489 50.9773 34.0864C51.2491 34.1734 51.4952 34.3307 51.6901 34.5427C51.885 34.7547 52.0234 35.0144 52.085 35.2951C52.5978 37.1388 50.2081 38.4244 48.526 40.4166C48.2593 40.7291 48.09 41.1116 48.0336 41.5188C47.9772 41.9255 48.0388 42.3398 48.2132 42.7121C48.3824 43.085 48.6593 43.4005 49.008 43.6217C49.3516 43.843 49.7567 43.9608 50.167 43.9608C50.5978 43.9592 51.0183 43.8394 51.3875 43.613C51.7516 43.3872 52.0491 43.0645 52.244 42.6804C53.7363 39.6945 58.7108 37.2976 55.9774 32.7496Z" fill="#B2B6BB" />
                    <path d="M49.8747 45.9425C49.3773 45.8432 48.8645 45.9056 48.4081 46.1197C47.9568 46.3343 47.5772 46.6892 47.3413 47.1328C47.1003 47.5763 47.0131 48.0849 47.0849 48.5827C47.1618 49.0805 47.3926 49.542 47.7465 49.8984C48.1055 50.2549 48.567 50.4874 49.0644 50.5622C49.5619 50.6365 50.0696 50.5489 50.5158 50.3123C50.9619 50.0751 51.3158 49.7018 51.5312 49.247C51.7466 48.7922 51.8132 48.28 51.7158 47.7863C51.6235 47.332 51.4029 46.9146 51.0747 46.5863C50.7465 46.2585 50.326 46.0342 49.8747 45.9425Z" fill="#B2B6BB" />
                    <g opacity="0.3">
                      <path opacity="0.3" d="M59.4761 51.3424C59.4469 51.3432 59.4183 51.3335 59.3952 51.3154C59.3723 51.2972 59.3563 51.2717 59.3501 51.243L53.0496 22.1725C53.0426 22.1392 53.0492 22.1045 53.0678 22.0759C53.0865 22.0473 53.1157 22.0273 53.1491 22.0203C53.1826 22.0133 53.2174 22.0199 53.246 22.0385C53.2746 22.0571 53.2947 22.0863 53.3016 22.1197L59.6021 51.1901C59.6057 51.2064 59.6059 51.2231 59.603 51.2395C59.6 51.2557 59.5938 51.2713 59.5849 51.2852C59.5758 51.2991 59.5641 51.3111 59.5505 51.3205C59.5367 51.33 59.5214 51.3365 59.5051 51.3399L59.4761 51.3424Z" fill="white" />
                    </g>
                    <g opacity="0.3">
                      <path opacity="0.3" d="M41.8353 51.3419H41.8089C41.7926 51.3385 41.7773 51.3318 41.7635 51.3224C41.7499 51.3131 41.7382 51.301 41.7292 51.2872C41.7202 51.2732 41.714 51.2577 41.711 51.2414C41.7081 51.2251 41.7083 51.2084 41.7119 51.1922L48.0124 22.1217C48.0158 22.1052 48.0226 22.0895 48.0321 22.0755C48.0416 22.0616 48.0539 22.0497 48.068 22.0404C48.0821 22.0312 48.098 22.0248 48.1146 22.0218C48.1312 22.0186 48.1482 22.0188 48.1649 22.0223C48.1814 22.0257 48.1971 22.0325 48.211 22.0419C48.225 22.0515 48.2369 22.0637 48.2461 22.0778C48.2555 22.092 48.2618 22.1077 48.2649 22.1244C48.2681 22.141 48.2678 22.1581 48.2644 22.1746L41.9639 51.245C41.9571 51.2736 41.9406 51.2989 41.9171 51.3166C41.8937 51.3344 41.8647 51.3433 41.8353 51.3419Z" fill="white" />
                    </g>
                  </svg>
                  <h5 className="my-2" >There are no journey created yet!</h5>
                  <p>If you want to create a new journey, click on journey and proceed.</p>
                </div> :
                <ListGroup as={"ul"} style={{ width: "100%" }} className="broadcast-overview-table">
                  <div className="broadcast-table-header">
                    <div>
                      <h4> Trigger Name </h4>
                    </div>
                    <div>
                      <h4>Reach</h4>
                      <h4>Opened</h4>
                      <h4>Clicked</h4>
                      <h4>CTR</h4>
                      <h4>Status</h4>
                      <h4 >Action</h4>
                    </div>
                  </div>
                  <div className="broadcast-table-content">
                    {activeCampaigns.list.map((list, i) => {
                      return (
                        <Fragment key={i}>
                          <ListGroup.Item
                            as={"li"}
                            className={
                              "org-list d-flex align-items-center justify-content-between my-2"
                            }
                          >
                            <div className="d-flex">
                              <div>
                                <h6 className="m-0"
                                  title={list.ruleName} >
                                  {" "}
                                  {list.ruleName}
                                </h6>
                                <span style={{ fontSize: '12px' }}>{list.timer}</span>
                              </div>
                            </div>
                            <div>
                              <h4>
                                {list.analytics.Sent}
                              </h4>
                              <h4>
                                {list.analytics.Opened}
                              </h4>
                              <h4>
                                {list.analytics.Clicked}
                              </h4>
                              <h4>
                                {getCTR(
                                  list.analytics.Sent,
                                  list.analytics.Clicked
                                ) || 0}
                                %
                              </h4>
                              <h4 style={{ textTransform: "capitalize" }}>
                                {list.ruleStatus === 'true' ? "enabled" : "disabled"}

                                <div className="custom-control custom-switch toggle-switch text-right mr-5 mb-2 mt-2">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={"customSwitch" + i}
                                    data-uuid={list.uuid}
                                    onChange={changeToggleStatus}
                                    checked={JSON.parse(list.ruleStatus)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={"customSwitch" + i}
                                  ></label>
                                </div>
                              </h4>
                              {/* <MoreVertIcon/> */}
                              <Dropdown className="text-center" onSelect={deleteRecipeFn} style={{ width: "10%" }}>
                                <Dropdown.Toggle
                                  as="button"
                                  variant=""
                                  className="btn sharp btn-primary tp-btn mt-1"
                                  id="tp-btn"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect x="0" y="0" width="24" height="24" />
                                      <circle fill="#000000" cx="12" cy="5" r="2" />
                                      <circle fill="#000000" cx="12" cy="12" r="2" />
                                      <circle fill="#000000" cx="12" cy="19" r="2" />
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ border: "1px solid #4c02ff" }}  >
                                  <Dropdown.Item style={{ color: "#4c02ff" }} eventKey={list.uuid}>
                                    Delete Journey <i className="fa fa-light fa-trash"></i>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </ListGroup.Item>
                        </Fragment>
                      );
                    })}
                  </div>
                </ListGroup>

            // <ListGroup as={"ul"} style={{ width: "99.55%" }}>
            //   {activeCampaigns.list.map((list, i) => {
            //     return (
            //       <Fragment key={i}>
            //         <ListGroup.Item
            //           style={{
            //             border: "1px solid grey",
            //             backgroundColor: "white",
            //             border: "none",
            //             borderRadius: "8px",
            //             width: "",
            //           }}
            //           as={"li"}
            //           className={
            //             "org-list d-flex align-items-center justify-content-between my-2"
            //           }
            //         >
            //           <div className="d-flex align-items-center justify-content-center'">
            //             {
            //               // <img className={"img-fluid mr-3"} src={broadcast1} />

            //             }
            //             <div>
            //               <h6 style={{ color: "black" }} className="m-0">
            //                 {" "}
            //                 {list.ruleName}
            //               </h6>
            //               <span>{list.timer}</span>
            //             </div>
            //           </div>
            //           <span
            //             style={{
            //               display: "flex",
            //               flexDirection: "row",
            //               justifyContent: "space-between",
            //               alignItems: "baseline",
            //               width: "55%",
            //             }}
            //           >
            //             <p>
            //               reach <br />{" "}
            //               <span style={{ color: "black", fontWeight: "500" }}>
            //                 {list.analytics.Sent}
            //               </span>
            //             </p>
            //             <p>
            //               opened <br />{" "}
            //               <span style={{ color: "black", fontWeight: "500" }}>
            //                 {list.analytics.Opened}
            //               </span>
            //             </p>
            //             <p>
            //               clicked <br />
            //               <span style={{ color: "black", fontWeight: "500" }}>
            //                 {" "}
            //                 {list.analytics.Clicked}
            //               </span>
            //             </p>
            //             <p>
            //               CTR <br />
            //               <span style={{ color: "black", fontWeight: "500" }}>
            //                 {getCTR(
            //                   list.analytics.Sent,
            //                   list.analytics.Clicked
            //                 ) || 0}
            //                 %
            //               </span>
            //             </p>
            //             <p>{list.ruleStatus === 'true' ? "enabled" : "disabled"}

            //               <div className="custom-control custom-switch toggle-switch text-right mr-5 mb-2">
            //                 <input
            //                   type="checkbox"
            //                   className="custom-control-input"
            //                   id={"customSwitch" + i}
            //                   data-uuid={list.uuid}
            //                   onChange={changeToggleStatus}
            //                   checked={JSON.parse(list.ruleStatus)}

            //                 />
            //                 <label
            //                   className="custom-control-label"
            //                   htmlFor={"customSwitch" + i}
            //                 ></label>
            //               </div>
            //             </p>
            //             {/* <MoreVertIcon/> */}
            //             <Dropdown onSelect={deleteRecipeFn}>
            //               <Dropdown.Toggle
            //                 as="button"
            //                 variant=""
            //                 className="btn sharp btn-primary tp-btn mt-1"
            //                 id="tp-btn"
            //               >
            //                 <svg
            //                   xmlns="http://www.w3.org/2000/svg"
            //                   xmlnsXlink="http://www.w3.org/1999/xlink"
            //                   width="18px"
            //                   height="18px"
            //                   viewBox="0 0 24 24"
            //                   version="1.1"
            //                 >
            //                   <g
            //                     stroke="none"
            //                     strokeWidth="1"
            //                     fill="none"
            //                     fillRule="evenodd"
            //                   >
            //                     <rect x="0" y="0" width="24" height="24" />
            //                     <circle fill="#000000" cx="12" cy="5" r="2" />
            //                     <circle fill="#000000" cx="12" cy="12" r="2" />
            //                     <circle fill="#000000" cx="12" cy="19" r="2" />
            //                   </g>
            //                 </svg>
            //               </Dropdown.Toggle>
            //               <Dropdown.Menu style={{ border: "1px solid #4c02ff" }}  >
            //                 <Dropdown.Item style={{ color: "#4c02ff" }} eventKey={list.uuid}>
            //                   Archive Journey <i className="fa fa-light fa-trash"></i>
            //                 </Dropdown.Item>
            //               </Dropdown.Menu>
            //             </Dropdown>
            //           </span>
            //         </ListGroup.Item>
            //       </Fragment>
            //     );
            //   })}

            // </ListGroup>


          }
        </div>

        {!activeCampaigns.loading && activeCampaigns.count >= 20 ?
          <Col l={12} className="col-lg-12">
            <Card style={{ backgroundColor: "transparent", marginBottom: '0' }} >
              <Card.Body className="pt-0">
                <Pagination
                  totalRecords={activeCampaigns.count}
                  pageLimit={perPage}
                  pageNeighbours={8}
                  currentPage={parseInt(page)}
                  onPageChanged={onPageChange}
                ></Pagination>
              </Card.Body>
            </Card>
          </Col>
          :
          <div style={{ height: "20px" }} ></div>

        }


      </>
    );
  }
};

export default JourneyOverview;
