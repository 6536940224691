import infoIcon from '../../../../../images/wigzolite icons/wigzolite-icons/dashboard/infoIcon.svg';
import { Tooltip } from '@material-ui/core';
export const WidgetCard = (props) => {
    return (
        <div className="widget-container">
            <div className="widget-data">
                <div className="widget-info d-flex align-items-center">
                    <h5>{props.title}</h5>
                    <Tooltip title={props.infoIconTitle}
                        arrow
                        placement="bottom"
                        style={{ display: !!props.infoIconTitle ? 'block' : 'none' }}>
                        <img src={infoIcon} />
                    </Tooltip>
                </div>
                <div className="widget-count">
                    {props.count}
                </div>
            </div>
            <div className="widget-icon">
                <img src={props.icon}></img>
            </div>
        </div>
    );
};