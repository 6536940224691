import { FormControl, MenuItem, Select } from "@material-ui/core";
import { subDays, addDays, startOfDay, endOfDay } from "date-fns";
import _debounce from "lodash/debounce";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Dropdown, ListGroup, } from "react-bootstrap";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { InfinitySpin } from "react-loader-spinner";
import { useHistory, useLocation } from "react-router-dom";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import swal from "sweetalert";
import "../../../css/broadcast.css";
import tpc from "../../../images/wigzolite icons/wigzolite-icons/dashboard/tpc.svg";
import searchIcon from "../../../images/wigzolite icons/wigzolite-icons/common/search_icon.svg";
import {
  deleteCampaign, getCampaignList
} from "../../../services/GetService";
import { store } from "../../../store/store";
import { audienceSegmentsReplacements, convertUTCToLocalTime, epochToLocalDate } from "../CommonApp";
import makeStyles from "@material-ui/core";
import Pagination from "../Dashboard/Home/Paignation";
import MessagePreviewModal from "./MessagePreviewModal";
import { broadcastChannels, campaignStatus } from "./BroadcastCommons";
import { email, emptyState, eyeIcon, onsite, SMS, webpush, whatsapp } from "./Svgs";
import { ThemeProvider, createTheme } from "@material-ui/core";
const BroadcastOverviewV2 = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { afterToday } = DateRangePicker;
  const history = useHistory();
  let [dateRange, setDateRange] = useState({
    startDate: subDays(new Date(), 180),
    endDate: new Date(),
  });
  const theme = createTheme({
    MuiItem: {
      " & .Mui-expanded": {
        margin: "0px !important",
        padding: "0px !important"
      }
    }

  });
  const [modalCentered, setModalCentered] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(20);
  const state = store.getState();
  const [index, setIndex] = useState(0);
  const [channel, setChannel] = useState("whatsapp");
  const [status, setStatus] = useState("RUNNING");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [campaignList, setCampaignList] = useState({ list: [], counts: "", loading: true });
  const predefinedDateRanges = [
    {
      label: 'today',
      value: [startOfDay(new Date()), endOfDay(new Date())]
    },
    {
      label: 'yesterday',
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
    },
    {
      label: 'last7Days',
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
    }
  ];
  const goToCreateForm = () => {
    !!searchParams.has('wib') ? history.push('/broadcast/create-broadcast?wib=1') : history.push("/broadcast/create-broadcast");
  };
  const pageChange = (e) => {
    setExpanded(false);
    onPageChange(e);
  };
  const getCampaignListFn = (startDate, endDate, channel, status, token, page, perPage, searchKeyword) => {
    document.getElementById("refreshButton").disabled = true;
    setCampaignList({ loading: true });
    getCampaignList(
      startDate,
      endDate,
      channel,
      status,
      token,
      page,
      perPage,
      searchKeyword
    ).then((response) => {
      setTotalPages(Math.ceil(parseInt(response.data.count)));
      setCampaignList({ list: response.data.campaigns, counts: response.data.count, loading: false });
      !!document.getElementById("refreshButton") && (document.getElementById("refreshButton").disabled = false);
    });
  };
  const changeBroadcastChannel = (e) => {
    setChannel(e.target.value);
    getCampaignListFn(moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      e.target.value === "All" ? e.target.value.replace(/All/g, "") : e.target.value,
      status,
      state.auth.org.org_token,
      page,
      perPage,
      searchKeyword);
  };
  const changeBroadcastStatus = (e) => {
    setStatus(e.target.value);
    getCampaignListFn(moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      channel === "All" ? channel.replace(/All/g, "") : channel,
      e.target.value,
      state.auth.org.org_token,
      page,
      perPage,
      searchKeyword);
  };
  const changeDateRange = (startDate, endDate) => {
    getCampaignListFn(moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      channel === "All" ? channel.replace(/All/g, "") : channel,
      status,
      state.auth.org.org_token,
      page,
      perPage,
      searchKeyword);
  };
  const deleteBroadcastCampaign = (e) => {
    swal({
      title: "Are you sure?",
      text: 'Do you want to delete campaign?',
      icon: "warning",
      closeOnClickOutside: true,
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
          className: "btn btn-light btn-sm"
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          closeModal: false,
          className: "btn btn-primary btn-sm swal-primary-btn"
        },
      },
    }).then((isConfirm) => {
      if (!!isConfirm) {
        deleteCampaign(e, state.auth.org.org_token);
        swal("Deleted Successfully", "", "success", {
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              closeModal: true,
              className: "btn btn-outline-primary btn-sm swal-outline-primary-btn"
            },
          },
        }).then((isClicked) => {
          getCampaignListFn(
            moment(dateRange.startDate).format("YYYY-MM-DD"),
            moment(dateRange.endDate).format("YYYY-MM-DD"),
            channel === "All" ? channel.replace(/All/g, "") : channel,
            status,
            state.auth.org.org_token,
            page,
            perPage,
            searchKeyword
          );
        });
      }
    });
  };
  let onPageChange = (e) => {
    e.preventDefault();
    getCampaignListFn(
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      channel === "All" ? channel.replace(/All/g, "") : channel,
      status,
      state.auth.org.org_token,
      e.target.innerText,
      perPage,
      searchKeyword);
    setPage(e.target.innerText);

  };
  useEffect(() => {
    getCampaignListFn(
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      channel === "All" ? channel.replace(/All/g, "") : channel,
      status,
      state.auth.org.org_token,
      page,
      perPage,
      searchKeyword
    );

  }, []);
  let resetSearch = () => {
    document.getElementById('searchCampaigns').value = '';
    getCampaignListFn(
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      channel === "All" ? channel.replace(/All/g, "") : channel,
      status,
      state.auth.org.org_token,
      page,
      perPage,
      ''
    );
    document.getElementById('resetSearch').style.display = 'none';

  };
  const searchCampaign = _debounce(
    (value) => {
      setSearchKeyword(value);
      if (value.length > 0) {
        document.getElementById('resetSearch').style.display = 'block';
        // let filteredData = campaignList.list.filter((item) => {
        //   let isFiltered = false;

        //   for (let key in item) {
        //     if (!!item.campaignName.toLowerCase().includes(value)) {
        //       isFiltered = true;
        //     }
        //   }
        //   return isFiltered;
        // });
        // setCampaignList({ list: filteredData });
      }
      getCampaignListFn(moment(dateRange.startDate).format("YYYY-MM-DD"),
        moment(dateRange.endDate).format("YYYY-MM-DD"),
        channel,
        status,
        state.auth.org.org_token,
        page,
        perPage,
        value);
    },
    700,
    []
  );

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  let closePreview = () => {
    setModalCentered(false);
  };



  return (
    <>
      <div style={!!searchParams.has('wib') ? { marginLeft: '3rem', paddingTop: '2rem' } : { paddingRight: "4.5rem", paddingLeft: '2rem' }} className="row content-body ">
        <div className="broadcast-overview-heading d-flex justify-content-between">
          <div>
            <h2>Campaigns</h2>
            <p>
              Easily design and send broadcast to reach customers at the right
              place
            </p>
          </div>
          <div>
            <button
              className="btn btn-primary btn-sm"
              onClick={goToCreateForm}
            >
              Create Campaign +
            </button>
          </div>
        </div>
        <div className="broadcast-overview-filters custom-selectable-secondary-dropdown">
          <div>
            {/* <div className="broadcast-filters-container">
              <label htmlFor="channelLabel">Channel</label>
              <FormControl style={{ minWidth: "130px" }}>
                <Select
                  variant="outlined"
                  labelId="channelLabel"
                  id="channelLabel"
                  value={channel}
                  onChange={changeBroadcastChannel}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {broadcastChannels.map((list, i) => {
                    return (
                      <MenuItem value={list.value} selected={i === 0} disabled={list.value === 'whatsapp' ? false : true}>
                        {list.key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div> */}

            <div className="broadcast-filters-container custom-selectable-secondary-dropdown">
              <label htmlFor="statusLabel">Status</label>
              <FormControl style={{ minWidth: "130px", }}>
                {/* <InputLabel style={{ marginLeft: "9%" }} id="statusLabel">
                Status
              </InputLabel> */}
                <Select
                  variant="outlined"
                  labelId="statusLabel"
                  id="statusLabel"
                  value={status}
                  onChange={changeBroadcastStatus}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {campaignStatus.map((list, i) => {
                    return (
                      <MenuItem value={list.key} selected={i === 0}>
                        {list.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <div className="broadcast-filters-container" style={{ margin: '0' }}>
              <Fragment>
                <img className="search-icon" src={searchIcon} />
                <input
                  style={{
                    width: "100%",
                    display: "block",
                    height: "45px",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                  }}
                  type="search"
                  id="searchCampaigns"
                  className="searchOrg form-control"
                  onChange={(e) => searchCampaign(e.target.value)}
                  autoComplete="false"
                  placeholder="Search by Campaign Name"
                />
              </Fragment>
              <p
                id="resetSearch"
                onClick={resetSearch}
                style={{
                  display: "none",
                  cursor: "pointer",
                  color: "grey",
                  position: "relative",
                  marginBottom: "0%",
                  right: "6%",
                  fontSize: "larger",
                }}>x</p>
            </div></div>
        </div>

        <div className="p-0 my-2 mt-4 d-flex align-items-baseline  justify-content-between col-xl-12 col-xxl-12">
          <h3 className="text-black " style={{
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px"
          }}>
            Campaign Overview
            <img style={{ marginLeft: "10px" }} src={tpc} />
          </h3>
          <Stack direction="row" spacing={8} alignItems="flex-start">
            <button
              className="mr-2 btn btn-outline-primary btn-sm"
              id="refreshButton"
              onClick={() => changeDateRange(dateRange.startDate, dateRange.endDate)}>
              <i className="fa fa-refresh"
                style={{ marginRight: '5px' }}></i>
              Refresh
            </button>
            <DateRangePicker
              ranges={predefinedDateRanges}
              block={true}
              defaultValue={[dateRange.startDate, dateRange.endDate]}
              cleanable={false}
              placement={"bottomEnd"}
              preventOverflow={true}
              shouldDisableDate={afterToday()}
              editable={false}
              onChange={(value) => {
                setDateRange({ startDate: value[0], endDate: value[1] });
                changeDateRange(value[0], value[1]);
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              format='dd-MM-yyyy'
            ></DateRangePicker>
          </Stack>
        </div>
        {

          campaignList.loading ?
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }} >
              <InfinitySpin color="#4c02ff" />
              <span>Hold on, We are fetching data for you.</span>
            </div> :

            campaignList.list.length <= 0 ?
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", justifyContent: "center", background: "white", borderRadius: "15px", padding: "15px" }} >
                {emptyState}
                <h5 className="my-2" >No Broadcasts created.</h5>
                <p>Click on 'Create Broadcast' to make a new one.</p>
              </div>
              :

              <ListGroup as={"ul"} style={{ width: "100%" }} className="broadcast-overview-table">
                <div className="broadcast-table-header">
                  <div>
                    <h4> Campaign Name </h4>
                  </div>
                  <div>
                    <h4>Reach</h4>
                    <h4>Sent</h4>
                    <h4>Delivered</h4>
                    <h4>Clicked</h4>
                    <h4>Orders</h4>
                    <h4>Revenue</h4>
                    <h4 >Action</h4>
                  </div>
                </div>
                <div className="broadcast-table-content">
                  {campaignList.list.map((list, i) => {
                    return (
                      <Fragment key={i}>
                        <ListGroup.Item
                          as={"li"}
                          className={
                            "org-list d-flex align-items-baseline justify-content-between my-2"
                          }
                        >
                          <div className="d-flex">
                            <span>
                              {list.selectedChannel === "whatsapp" ? (
                                whatsapp
                              ) : list.selectedChannel === "sms" ? (
                                SMS
                              ) : list.selectedChannel === "email" ? (
                                email
                              ) : list.selectedChannel === "push" ? (
                                webpush
                              ) : list.selectedChannel === "onsitepush" ? (
                                onsite
                              ) : (
                                ""
                              )}
                            </span>

                            <div>
                              <h6 className="m-0"
                                title={list.campaignName} >
                                {" "}
                                {list.campaignName}
                              </h6>
                              <div>
                                <ThemeProvider theme={theme} >

                                  <Accordion style={{ boxShadow: "none", }} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                                    <AccordionSummary
                                      style={{ width: "5vw" }}
                                      className="broadcastOverviewAccordion"
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"
                                    >
                                      <Typography style={{ height: "20px", flexShrink: 0, margin: "0px" }}>
                                        <p style={{ fontSize: "13px", color: "#4c02ff", cursor: "pointer", marginBottom: "0px" }} id={i}>
                                          {expanded === `panel${i}` ? "Hide details" : " View details"}
                                        </p>
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="broadcastOverviewAccordion" >
                                      <Typography className="broadcastOverviewAccordion" >
                                        <div>
                                          <div id={`${i}${i}`} className="broadcast-view-details" >
                                            <p onClick={() => {
                                              setIndex(i);
                                              setModalCentered(true);
                                            }}>Show preview {eyeIcon}</p>

                                            <span>
                                              Template:<span title={list.campaignData.selectedTempName} >{list.campaignData.selectedTempName}</span>
                                            </span>
                                            <span >
                                              Audience:<span title={audienceSegmentsReplacements(list.campaignData.targetedAudience.selectedSegmentName)}>{audienceSegmentsReplacements(list.campaignData.targetedAudience.selectedSegmentName)}</span>
                                            </span>
                                            <span>
                                              Created At:
                                              <span title={moment(convertUTCToLocalTime(list.createdAt)).format("DD-MM-YYYY HH:mm ")}>
                                                {moment(convertUTCToLocalTime(list.createdAt)).format("DD-MM-YYYY HH:mm ")}
                                              </span>
                                            </span>
                                            <span style={{ display: list.campaignData.status === 'SCHEDULED' || list.campaignData.status === 'RECURRING' ? 'flex' : 'none' }}>
                                              {list.campaignData.status === 'SCHEDULED' && 'Scheduled On' || list.campaignData.status === 'RECURRING' && 'Start Date'}:
                                              <span title={moment(epochToLocalDate(list.campaignData.scheduleAt)).format("DD-MM-YYYY HH:mm ")}>
                                                {moment(epochToLocalDate(list.campaignData.scheduleAt)).format("DD-MM-YYYY HH:mm ")}
                                              </span>
                                            </span>
                                            <span style={{ display: list.campaignData.status === 'RECURRING' ? 'flex' : 'none' }}>
                                              Recurring days:<span title={list.campaignData.repeatAfterDays} >{list.campaignData.repeatAfterDays}</span>
                                            </span>
                                            <span style={{ display: list.campaignData.status === 'RECURRING' ? 'flex' : 'none' }}>
                                              End Date:
                                              <span title={moment(new Date(list.campaignData.repeatStopDateTime)).format("DD-MM-YYYY HH:mm ")}>
                                                {moment(new Date(list.campaignData.repeatStopDateTime)).format("DD-MM-YYYY HH:mm ")}
                                              </span>
                                            </span>
                                          </div >
                                        </div>
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                </ThemeProvider>

                              </div>

                            </div>
                          </div>
                          <div>
                            <h4>
                              {list.campaignData.targetedAudience.estimateReach}
                            </h4>
                            <h4>
                              {list.sent}
                            </h4>
                            <h4>
                              {list.received}
                            </h4>
                            {/* <p>
                          Delivered <br />{" "}
                          <span style={{ color: "black", fontWeight: "500" }}>
                            {list.received}
                          </span>
                        </p> */}
                            {/* <p>
                          Opened <br />{" "}
                          <span style={{ color: "black", fontWeight: "500" }}>
                            {list.opened}
                          </span>
                        </p> */}
                            <h4>
                              {list.clicked}
                            </h4>
                            <h4>
                              {list.orderNumber}
                            </h4>
                            <h4>
                              {list.revenue}
                            </h4>
                            {/* <p className="text-center"  style={{width:"60px"}} >
                         
                         <span style={{ color: "#707070", fontWeight: "500" }}>
                           {list.orderNumber}
                         </span>
                       </p>
                       <p className="text-center"  style={{width:"60px"}} >
                         
                         <span style={{ color: "#707070", fontWeight: "500" }}>
                           {list.revenue}
                         </span>
                       </p> */}
                            {/* <p>
                    Revenue <br />
                    <span style={{ color: "black", fontWeight: "500" }}>
                      {list.revenue}
                    </span>
                  </p> */}
                            {/* <MoreVertIcon/> */}
                            <Dropdown className="text-center" onSelect={deleteBroadcastCampaign} style={{ width: "10%" }}>
                              <Dropdown.Toggle
                                as="button"
                                variant=""
                                className="btn sharp btn-outline-primary tp-btn"
                                id="tp-btn"
                                style={{ color: '#4c02ff', backgroundColor: '#fff', margin: '0', padding: '0', height: 'unset', minHeight: 'unset' }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <circle fill="#000000" cx="12" cy="5" r="2" />
                                    <circle fill="#000000" cx="12" cy="12" r="2" />
                                    <circle fill="#000000" cx="12" cy="19" r="2" />
                                  </g>
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu style={{ border: "1px solid #4c02ff" }} >
                                <Dropdown.Item style={{ color: "#4c02ff" }} eventKey={list.uuid}>
                                  {'Delete Campaign'}  <i className="fa fa-light fa-trash"></i>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </ListGroup.Item>
                      </Fragment>
                    );
                  })}
                </div>
              </ListGroup>
        }
      </div>
      {
        !campaignList.loading && campaignList.counts >= 20 &&
        <Col l={12} className="col-lg-12">
          <Card style={{ backgroundColor: "transparent", marginBottom: "0", boxShadow: "none" }} >
            <Card.Body className="pt-0">
              <Pagination
                totalRecords={campaignList.counts}
                pageLimit={parseInt(perPage)}
                pageNeighbours={8}
                currentPage={parseInt(page)}
                onPageChanged={pageChange}
              ></Pagination>
            </Card.Body>
          </Card>
        </Col>
      }
      <MessagePreviewModal closeModal={closePreview} modalCentered={modalCentered} setModalCentered={setModalCentered} message={!!campaignList.list && campaignList.list[index]?.campaignData?.message} attachment={!!campaignList.list && campaignList.list[index]?.campaignData?.replacedAttachment} replacedButtonUrl={!!campaignList.list && campaignList.list[index]?.campaignData?.replacedButtonUrl} org_name={state.auth.org.org_name} footer={!!campaignList.list && campaignList.list[index]?.campaignData?.footer} />
    </>
  );
};

export default BroadcastOverviewV2;