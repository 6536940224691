export const broadcastChannels = [
  { key: "Whatsapp", value: "whatsapp" },
  // { key: "SMS", value: "sms" },
  // { key: "Email", value: "email" },
  // { key: "Push", value: "push" },
  { key: "Onsite", value: "onsitepush" },
];
export const campaignStatus = [
  { key: "RUNNING", label: "Sent" },
  { key: "RECURRING", label: "Recurring" },
  { key: "SCHEDULED", label: "Scheduled" },
  { key: "PROCESSING", label: "Processing" },
  // { key: "WAITING", label: "Waiting" },
];