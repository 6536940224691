import _ from "lodash";
export const epochToLocalDate = function (date) {
    var utcSeconds = date;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    return new Date(d.setUTCSeconds(utcSeconds));
};
export let getClubbedDates = (dates, values) => {
    //values can also be multidimensional
    let summedValuesObject = {};
    let summedValues = {};
    let datesArray = [];
    let resultObject = {};
    let vals = [];
    let multiplier;

    //handling time period 
    switch (!!dates.length) {
        case dates.length >= 20:
            multiplier = 7;
            break;
        case dates.length >= 40:
            multiplier = 14;
            break;
        case dates.length >= 60:
            multiplier = 21;
            break;
        case dates.length >= 80:
            multiplier = 21;
            break;
        default:
            multiplier = 1;
    }

    //pushing clubbed dates into an array
    for (let i = 0; i < (dates.length) / multiplier; i++) {
        datesArray.push(dates[i * multiplier]);

    }
    datesArray.push(dates[dates.length - 1]);

    //partitioning values array and summing them 
    let partitionedArray = () => {
        //checking if the array is multidimensional
        if (multiplier !== 1) {
            if (Array.isArray(values[0])) {
                for (let i = 0; i < values.length; i++) {
                    for (let j = 0; j < values[i].length; j++) {
                        summedValuesObject[j] = values[i].splice(-multiplier)
                            .reduce((partialSum, a) => partialSum + a, 0);
                        summedValues[i] = Object.values(summedValuesObject);
                    }
                }
                return summedValues;
            }
            else {
                for (let j = 0; j < datesArray.length; j++) {
                    summedValuesObject[j] = values.splice(-datesArray.length)
                        .reduce((partialSum, a) => partialSum + a, 0);
                    summedValues = Object.values(summedValuesObject);
                }
                return summedValues;

            }
        }
    };
    //wrapping up the result into an object and returning the result

    vals = partitionedArray();
    resultObject["dates"] = datesArray != null ? datesArray : dates;
    resultObject['values'] = vals != null ? vals : values;
    return resultObject;

};
export let getDataChunks = (data, dataType) => {
    let chartDataTypes = {
        NUMBER: 'number', DATE: "date"
    };
    if (!data.length || !dataType || data.length <= 10) { // if Array length is less than 10 or
        return data;
    }
    let chunks = [];
    let chunkSize = Math.ceil(data.length / 10);
    if (dataType === chartDataTypes.NUMBER) {
        chunks = _.map(_.chunk(data, chunkSize), (t) => {
            return _.sum(t);
        });
    } else if (dataType === chartDataTypes.DATE) {
        chunks = _.map(_.chunk(data, chunkSize), (t, index) => {
            let retArr = index === 0 ? t[0] : t[t.length - 1];
            return retArr;
        });
    }
    return chunks;
};
export let getFormattedNumbers = (numbers) => {
    let formattedNumbersArray = [];
    numbers.forEach(number => {
        //let formattedNumberInString= Math.abs(number) > 999 ? Math.sign(number)*((Math.abs(number)/1000).toFixed(1)) + 'k' : Math.sign(number)*Math.abs(number);
        formattedNumbersArray.push(number / 1000);
    });
    return formattedNumbersArray;
};
export let getCTR = (sentCount, clickedCount) => {
    return (
        !!sentCount &&
        !!clickedCount &&
        !!Number(sentCount) &&
        !!Number(clickedCount) &&
        ((Number(clickedCount) / Number(sentCount)) * 100).toFixed(2)
    );
};
export let convertUTCToLocalTime = (dateString) => {
    let date = new Date(dateString);
    const milliseconds = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    );
    const localTime = new Date(milliseconds);
    localTime.getDate(); // local date
    localTime.getHours(); // local hour
    return localTime; //return an object
};
export let audienceSegmentsReplacements = (segment) => {
    switch (segment) {
        case 'sendToAll':
            return 'Send to all registered users';
            break;
        case '1_MONTH_PURCHASE_LITE':
            return 'Recently purchased in last 30 days';
            break;
        case '2_MONTH_PURCHASE_LITE':
            return 'Recently purchased in last 60 days';
            break;
        case '3_MONTH_PURCHASE_LITE':
            return 'Recently purchased in last 90 days';
            break;
        case 'NEVER_PURCHASED_LITE':
            return 'New Customers';
            break;
        case 'INACTIVE_CUSTOMER_LITE':
            return 'Inactive Customers';
            break;
        case 'BIG_TICKET_SPENDERS_LITE':
            return 'Big Spenders';
            break;
    }
};