import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateOnboardingStatus, updateWalletBalance } from "../../services/AuthService";
import "../../css/login.css";
import "../../css/landingPage.css";
import { Button, Modal } from "react-bootstrap";
// import termsCondittionJson from "https://media.shipconvert.com/SRConvert/tc.json";
import conversion from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/conversion.png";
import colorBar from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/color-bar.svg";
import feedback from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/feedback.svg";
import handShake from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/hand-shake.svg";
import promotion from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/promotion.svg";
import target from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/target.svg";
import conversionBoxG from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/conversion-box-green.png";
import conversionBoxB from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/conversion-box-blue.png";
import conversionBoxY from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/conversion-box-yellow.png";
import leftArrow from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/left-arrow.svg";
import rightArrow from "../../images/wigzolite icons/wigzolite-icons/landingPageIcons/right-arrow.svg";

const LandingPage = () => {
    console.log();
    // const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const history = useHistory();
    // const [activeWithoutSpaceBordered, setActiveWithoutSpaceBordered] = useState(0);
    let orgDetails = JSON.parse(localStorage.getItem('orgDetails'));
    const [termsCondittionJson, setTermsCondittionJson] = useState({});
    const [modalCentered, setModalCentered] = useState(false);
    let aggregatedInsights = [
        {
            h2: '25%',
            span: 'Better user retention'
        },
        {
            h2: '15%',
            span: 'Higher average revenue'
        },
        {
            h2: '30%',
            span: 'More cart recoveries'
        },
        {
            h2: '20x',
            span: 'Improved ROI'
        },
    ];
    let aggregatedInsightsConv = [
        {
            img: handShake,
            h2: 'Welcome Greetings',
            span: 'Greet & impress new visitors'
        },
        {
            img: promotion,
            h2: 'Sales promotion',
            span: 'Grow revenue with templatised broadcast'
        },
        {
            img: target,
            h2: 'Behavioural retargeting',
            span: 'Trigger FOMO for quicker conversion'
        },
        {
            img: target,
            h2: 'Cart recovery',
            span: 'Remind & guide visitors to check out'
        },
        {
            img: target,
            h2: 'Upselling & cross-selling',
            span: 'Drive repeat purchases & brand loyalty'
        },
        {
            img: feedback,
            h2: 'Review requests',
            span: 'Get customer feedback effortlessly'
        },
    ];

    let defaultAccordion = [
        {
            title: 'Lorem Ipsum is simply dummy?',
            text: 'Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean',
            bg: 'primary',
        },
        {
            title: 'Lorem Ipsum is simply dummy?',
            text: 'Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean',

            bg: 'info',
        },
        {
            title: 'Lorem Ipsum is simply dummy?',
            text: 'Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean',

            bg: 'success',
        },
        {
            title: 'Lorem Ipsum is simply dummy?',
            text: 'Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean commodo ligula eget dolor. massa dis parturient Lorem ipsum dolor sit amet, sectetuer eds adipiing elit. Aenean',

            bg: 'success',
        },
    ];
    let checkChannelPermission = () => {
        updateOnboardingStatus();
        let authDetails = JSON.parse(localStorage.getItem('authDetails'));
        if (orgDetails.permissions === 'granted') {
            history.push('/dashboard');
            // updateWalletBalance(dispatch, orgDetails.org_token, orgDetails.permissions, authDetails.onboarding_status);
        } else {
            history.push('/channellist');
        }
    };
    function rep(text) {

        // Put the URL to variable $1 after visiting the URL
        var Rexp =
            /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;

        // Replace the RegExp content by HTML element
        return !!text && text.replace(Rexp,
            `<a href='$1' target='_blank'>$1</a>`);
    }
    useEffect(() => {
        Object.keys(termsCondittionJson).length === 0 && fetch('https://media.shipconvert.com/SRConvert/tc.json').then((response) => response.json())
            .then((res) => {
                setTermsCondittionJson(res);
            }).catch((error) => {
                console.error(error);
            });
    }, []);
    return (
        <div className="row content-body ">
            <div className="col-lg-12 col-md-12">
                {/* <h1>landing page</h1>
                <button className='mr-2 btn btn-primary' style={{ 'background': '#86a136' }}
                    onClick={checkChannelPermission}>Activate Convert</button> */}
                <div className="landing-page-container">
                    <div>
                        <div>
                            <h1>Boost your conversions</h1>
                            <h2>by upto 40%</h2>
                            <p>Our intelligent re-engagement platform helps you bring back high-potential prospects and retain them for long.</p>
                            <div>
                                <button className="mr-2 btn btn-primary" onClick={checkChannelPermission} style={{ fontSize: "0.765vw", borderRadius: '10px', background: '#735AE5', padding: '0.601vw 2.185vw', width: "9vw", fontWeight: '600', boxShadow: '0px 24px 48px #4429be3c' }}>Get Started</button>
                                <img src={leftArrow} alt="left Arrow"></img>
                            </div>
                            <div><span>By continuing, you agree to the <a onClick={() => setModalCentered(true)}>Terms & Conditions</a></span></div>
                        </div>
                        <img src={conversion}></img>
                    </div>
                    <div>
                        <p>Accelerate Your Business Growth</p>
                        <div>
                            {aggregatedInsights.map((value, i) => {
                                return (
                                    <div key={i}>
                                        <h2><img src={colorBar}></img> {value.h2}</h2>
                                        <span>{value.span}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div>
                        <h1><span>Automate</span> every conversation</h1>
                        <p>Easily reconnect with people interested in your brandusing powerful features.</p>
                        <div>
                            {aggregatedInsightsConv.map((value, i) => {
                                return (
                                    <div key={i}>
                                        <h4><img src={value.img}></img> {value.h2}</h4>
                                        <span>{value.span}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div>
                        <h1>From prospects to <span>loyal customers</span></h1>
                        <p>Take your audience on a personalised journey driven by data.</p>
                        <div>
                            <div>
                                <h1>Delight your visitors</h1>
                                <p>Give your potential customers a great first impression.</p>
                                <div style={{ marginTop: '35px', borderLeft: '3px solid #b8a9fd' }}>
                                    <p>Welcome</p>
                                    <span>Share greetings with your visitors, encouraging them to keep browsing.</span>
                                </div>
                                <div style={{ background: 'none', border: '0' }}>
                                    <p style={{ color: '#000000E6' }}>Engage</p>
                                    <span>Broadcast personalised messages to pre-segmented users, selecting a template of your choice.</span>
                                </div>
                            </div>
                            <img src={conversionBoxY}></img>
                        </div>
                        <div style={{ background: '#F6FFF0' }}>
                            <img src={conversionBoxG}></img>
                            <div>
                                <h1>Convert them faster</h1>
                                <p>Nudge your prospects towards making a purchase.</p>
                                <div style={{ marginTop: '35px', borderLeft: '3px solid #b8a9fd' }}>
                                    <p>Convince</p>
                                    <span>Create urgency by sending targeted messages based on user behaviour.</span>
                                </div>
                                <div style={{ background: 'none', border: '0' }}>
                                    <p style={{ color: '#000000E6' }}>Remind</p>
                                    <span>Recover abandoned carts using persuasive reminders & increase conversions by 30%.</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ background: '#EDF6FC' }}>
                            <div>
                                <h1>Retain them better</h1>
                                <p>Reap the rewards of trust to increase customer lifetime value.</p>
                                <div style={{ marginTop: '35px', borderLeft: '3px solid #b8a9fd' }}>
                                    <p>Capitalise</p>
                                    <span>Increase your average order value through upselling & cross-selling.</span>
                                </div>
                                <div style={{ background: 'none', border: '0' }}>
                                    <p style={{ color: '#000000E6' }}>Treasure</p>
                                    <span>Make customers feel valued by requesting them to share reviews.</span>
                                </div>
                            </div>
                            <img src={conversionBoxB}></img>
                        </div>
                    </div>
                    <div className="start-now-sticker">
                        <div>
                            <div>
                                <h1>How interested are you?</h1>
                                <p>People are already interested in buying from your store. Reach out to them before it’s too late.</p>
                            </div>
                            <div>
                                <img src={rightArrow}></img>
                                <button className="mr-2 btn btn-primary" onClick={checkChannelPermission}>Start now</button>
                            </div>
                        </div>
                        <div></div>
                    </div>

                    {/* <div className="FAQ-accordian">
                        <h1><span>Frequently asked</span> questions</h1>
                        <div>
                            <Accordion
                                className='accordion accordion-no-gutter '
                                defaultActiveKey='0'
                            >
                                {defaultAccordion.map((d, i) => (
                                    <div className='accordion__item' key={i}>
                                        <Accordion.Toggle
                                            as={Card.Text}
                                            eventKey={`${i}`}
                                            className={`accordion__header ${activeWithoutSpaceBordered === i ? '' : 'collapsed'
                                                }`}
                                            onClick={() =>
                                                setActiveWithoutSpaceBordered(
                                                    activeWithoutSpaceBordered === i ? -1 : i
                                                )
                                            }
                                        >
                                            <span className='accordion__header--text'>{d.title}</span>
                                            <span className='accordion__header--indicator style_two'></span>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={`${i}`}>
                                            <div className='accordion__body--text'>{d.text}</div>
                                        </Accordion.Collapse>
                                    </div>
                                ))}
                            </Accordion>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* <!-- T&C Modal --> */}<Modal className="fade TC-modal" show={modalCentered}>
                <Modal.Header>
                    <Modal.Title>Terms and Condition for Shiprocket Convert</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={() => setModalCentered(false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {/* <p dangerouslySetInnerHTML={{ __html: rep(termsCondittionJson.header) }}>
                    </p> */}
                    <table className="TC-table">
                        {Object.keys(termsCondittionJson).map((key, i) => {
                            return (
                                // <div key={i}>
                                //     <h4>{key}</h4>
                                //     <p dangerouslySetInnerHTML={{ __html: rep(termsCondittionJson[key]) }}></p>
                                //     {/* <p dangerouslySetInnerHTML={{ __html: termsCondittionJson[key] }}></p> */}
                                // </div>
                                <tr>
                                    <td><h5>{key}</h5></td>
                                    <td>
                                        <p dangerouslySetInnerHTML={{ __html: rep(termsCondittionJson[key]) }}></p>
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </Modal.Body>
            </Modal>
        </div >
    );
};
export default LandingPage;